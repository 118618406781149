<template>
  <div class="speakers">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteSpeaker" />
    <div class="flex align-center justify-between speakers-head">
      <h3>Speakers</h3>
      <router-link to="/admin/speakers/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Speaker</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">Photo</th>
          <th class="text-left">Title</th>
          <th class="text-left">University</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!speakers.length && !loading"><td class="text-left semibold pl-3" colspan="4">No speakers were found. Please add some!</td></tr>
        <tr v-for="(speaker, index) in speakers" :key="speaker._id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            <div
              class="photo-container"
              :style="
                `background-image: url(${speaker.photo}`
              "
            ></div>
          </td>
          <td class="text-left">{{ speaker.title }}</td>
          <td class="text-left">{{ speaker.university }}</td>
          <td class="text-center">
            <router-link :to="`/admin/speakers/${speaker._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="speakerDeletion(speaker)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!speakers.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .photo-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: auto;
    background-size: contain;
  }
  .speakers {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 150%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';
  
  export default {
    name: 'Speakers',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getSpeakers();
    },
    computed: {
      ...mapGetters(['speakers','loading'])
    },
    methods: {
      ...mapActions(['getSpeakers', 'deleteSpeaker']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      speakerDeletion(speaker) {
        this.deleteShownModal = true;
        this.deletion_id = speaker;
      },
      onDeleteSpeaker() {
        this.deleteSpeaker(this.deletion_id);
        this.deleteShownModal = false;
      }
    },
    components: {
      DeleteModal,
    },
  }
</script>
