import router from '@/router';

export default {
    state: {
        importantDates: [],
        importantDate: {}
    },
    mutations: {
        setImportantDates(state, importantDates) {
            state.importantDates = importantDates;
        },
        addImportantDate(state, importantDate) {
            state.importantDates = [...state.importantDates, importantDate];
        },
        setImportantDate(state, importantDate) {
            state.importantDate = importantDate;
        }
    },
    actions: {
        getImportantDates({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('important-dates')
                .then(({ data }) => {
                    commit('setImportantDates', data.importantDates);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addImportantDate({ commit }, importantDate) {
            this._vm.$axios.post('important-dates', importantDate)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addImportantDate', data.importantDate);
                    router.push('/admin/important-dates');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getImportantDate({ commit }, importantDate_id) {
            this._vm.$axios.get(`important-dates/${importantDate_id}`)
                .then(({ data }) => {
                    commit('setImportantDate', data.importantDate);
                })
                .catch(err => console.log(err));
        },
        editImportantDate({ commit }, importantDate) {
            this._vm.$axios.put(`important-dates/${importantDate._id}`, importantDate)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/important-dates');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteImportantDate({ commit, getters }, importantDate) {
            this._vm.$axios.delete(`important-dates/${importantDate._id}`)
                .then(({ data }) => {
                    const importantDates = getters.importantDates.filter(item => item._id != importantDate._id);
                    commit('setImportantDates', importantDates);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        importantDates(state) {
            return state.importantDates;
        },
        importantDate(state) {
            return state.importantDate;
        }
    }
}