<template>
  <div class="footer primary-bg">
    <div
      class="footer-inner container-fluid flex align-center justify-between py-4"
    >
      <div class="logo">
        <router-link to="/" exact>
          <img src="@/assets/logoup.png" alt="Universiteti i Prishtines" title="University of Prishtina" class="logo-img">
          <img src="@/assets/ilirias.png" alt="Universiteti i Prishtines" title="Ilirias" class="logo-img">
        </router-link>
      </div>
      <p>Copyright &copy; {{ new Date().getFullYear() }} Naim Braha. All rights reserved</p>
      <div class="footer-links">
        <ul class="flex align-center justify-center">
          <li><router-link to="/participants/register">Register</router-link></li>
          <li><router-link to="/participants">Participants</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .footer {
    color: #fff;
    &-inner {
      gap: 30px;
      @include for-tablet-landscape {
        flex-wrap: wrap;
      }
      @include for-phone-only {
        flex-wrap: nowrap;
        flex-direction: column;
      }
      p {
        color: #fff;
        font: $font !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        @include for-tablet-landscape {
          margin: 0 12px;
        }
      }
      .footer-links {
        @include for-tablet-landscape {
          flex: 1;
        }
        ul {
          @include for-phone-only {
            flex-direction: column;
          }
          li {
            a {
              color: #fff;
              font: $font !important;
              font-size: 18px;
              font-weight: 400;
              line-height: 21px;
              transition: transform .5s ease;
              display: inline-block;
              -webkit-font-smoothing: subpixel-antialiased;
              &:hover {
                transform: scale(1.1);
              }
            }
            &:not(:last-child) {
              margin-right: 40px;
              @include for-phone-only {
                margin-right: 0;
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'Footer',
};
</script>
