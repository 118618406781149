<template>
    <div class="register main-spacing">
        <div class="text-container">
            <div class="text" v-for="text in textsByPage" :key="text._id">
                <h2 class="main-title mb-3">{{ text.title }}</h2>
                <div class="text-content" v-html="text.content">
                </div>
            </div>
        </div>
        <form @submit.prevent="submitForm" class="registration-form mt-7">
            <InputSelect
             :selected="participant.title"
             @change="participant.title = $event"
             label="Title *"
             placeholder="Select title"
             :options="['Prof.Dr.', 'Assoc.Prof.Dr.', 'Assist.Prof.Dr', 'Dr.']"
             color="white"
             class="half-width">
            </InputSelect>
            <div class="form-control flex">
                <Input required="true" v-model="participant.name" type="text" name="name" label="Name *" placeholder="Enter name" />
                <Input required="true" v-model="participant.surname" type="text" name="surname" label="Surname *" placeholder="Enter surname" />
            </div>
            <div class="form-control flex">
                <Input required="true" v-model="participant.email" type="email" name="email" label="Email *" placeholder="Enter email address" />
                <Input required="true" v-model="participant.phone_number" type="text" name="phone_number" label="Phone number *" placeholder="Enter phone number" />
            </div>
            <div class="form-control flex">
                <Input required="true" v-model="participant.university" type="text" name="university" label="University *" placeholder="Enter university" />
                <Input required="true" v-model="participant.department" type="text" name="department" label="Department *" placeholder="Enter department" />
            </div>
            <div class="form-control flex">
                <InputSelect 
                 @change="participant.topic = $event"
                 :selected="participant.topic"
                 :options="
                    [
                        'Numerical Analysis',
                        'Ordinary and Partial Differential Equations',
                        'Scientific Computing',
                        'Boundary Value Problems',
                        'Approximation Theory',
                        'Sequence Spaces and Summability',
                        'Real Analysis',
                        'Functional Analysis',
                        'Fixed Point Theory',
                        'Optimization, Geometry',
                        'Computational Geometry',
                        'Differential Geometry',
                        'Applied Algebra',
                        'Combinatorics',
                        'Complex Analysis',
                        'Flow Dynamics',
                        'Computing Theory',
                        'Numerical and Semi-Numerical Algorithms',
                        'Game Theory',
                        'Operations Research',
                        'Optimization Techniques',
                        'Fuzzy Sequence Spaces',
                        'Symbolic Computation',
                        'Fractals and Bifurcations',
                        'Analysis and Design Tool',
                        'Cryptography',
                        'Mathematical Modelling in Scientific Disciplines',
                        'Number Theory and Mathematics Education',
                        'Finance Mathematics',
                        'Fractional Dynamics',
                        'Fuzzy Systems and Fuzzy Control',
                        'Dynamical Systems and Chaos'
                    ]
                 "
                 label="topic *"
                 placeholder="Select topic"
                 color="white"
                />
                <InputSelect
                 :selected="participant.presentation_type"
                 @change="participant.presentation_type = $event" 
                 label="PRESENTATION TYPE *" 
                 :options="['Poster', 'Online', 'Oral']"
                 placeholder="Select presentation type" 
                 color="white" 
                />
            </div>
            <div class="form-control flex">
                <Input required="true" v-model="participant.abstract_title" class="half-width" type="textarea" label="Abstract Title *" placeholder="Enter abstract title" name="abstract_title" />
                <div class="flex align-start flex-column">
                    <label for="abstract_file" class="primary-color bold uppercase">Abstract File</label>
                    <input
                        required="true"
                        id="abstract_file"
                        type="file"
                        ref="abstractFile"
                        @change="selectFile"
                    />
                </div>
            </div>
            <div class="form-control flex">
                <InputSelect 
                 :selected="participant.accomodation_type"
                 @change="participant.accomodation_type = $event" 
                 label="ACCOMODATION TYPE *" 
                 :options="['Regular', 'Student']"
                 placeholder="Select accomodation type" 
                 color="white" 
                />
                <InputSelect 
                 :selected="participant.room_type"
                 @change="participant.room_type = $event" 
                 label="ROOM TYPE *" 
                 :options="['Single Room', 'Double Room', 'Triple Room']"
                 placeholder="Select room type"
                 color="white" 
                />
            </div>
            <Input class="mb-4" type="radio" :options="['yes', 'no']" name="accompanying_persons" label="Accompanying Persons *" @checkboxSelected="checkboxSelected"/>
            <div class="flex mb-4 accompanying-persons" v-if="register.accompanyingPersons">
                <Input v-model="participant.person_1" type="text" name="department" label="Accompanying Person 1" placeholder="Enter Accompanying Person 1" class="flex-full mr-2"/>
                <Input v-model="participant.person_2" type="text" name="university" label="Accompanying Person 2" placeholder="Enter Accompanying Person 2" class="flex-full mr-2"/>
                <Input v-model="participant.person_3" type="text" name="department" label="Accompanying Person 3" placeholder="Enter Accompanying Person 3" class="flex-full "/>
            </div>
            <div class="form-control flex">
                <Input required="true" v-model="participant.checkin_date" type="date" label="Hotel check in date" name="checkin_date" />
                <Input required="true" v-model="participant.checkout_date" type="date" label="Hotel check out date" name="checkout_date" />
            </div>
            <button type="submit" class="primary-btn">Register</button>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    .half-width {
        width: calc(50% - 32px);
        margin-bottom: 38px;
        @include for-phone-only{
             width: 100%;
             margin-bottom: 17px;
        }
    }
    .registration-form {
        max-width: 1021px;
    }
    .form-control {
        @include for-phone-only{
            flex-direction: column;
        }
    }
    .form-control * {
        width: calc(50% - 32px);
        margin-bottom: 38px;
        &:first-child {
            margin-right: 64px;
        }
        @include for-phone-only{
             width: 100%;
             margin-bottom: 17px;
        }
    }
    .accompanying-persons{
        @include for-phone-only{
            flex-direction: column;
            margin-bottom: 17px !important;
            margin-top: -10px !important;
            > div{
                margin: 0 0 20px 0 !important;
            }
        }
    }
</style>

<script>
    import InputSelect from '@/components/shared/InputSelect.vue'; 
    import Input from '@/components/shared/Input.vue';
    import { mapActions,mapGetters } from 'vuex';

    export default {
        name: 'Register',
        data(){
            return {
                register: {
                    accompanyingPersons: false,
                },
                participant: {
                    title: '',
                    name: '',
                    university: '',
                    topic: '',
                    abstract_title: '',
                    abstract_file: '',
                    surname: '',
                    phone_number: '',
                    department: '',
                    presentation_type: '',
                    accomodation_type: '',
                    room_type: '',
                    checkin_date: '',
                    checkout_date: '',
                    person_1: '',
                    person_2: '',
                    person_3: '',
                    status: false,
                    email: ''
                }
            }
        },
        methods: {
            ...mapActions(['addParticipant', 'getTextsByPage']),
            checkboxSelected(value) {
                if(value === "yes") {
                    this.register.accompanyingPersons = true;
                }
                else if(value === "no") {
                    this.register.accompanyingPersons = false;
                }
            },
            selectFile(e) {
                var files = e.target.files || e.dataTransfer.files;
                if(!files.length)
                    return;
                this.participant.abstract_file = files[0];
            },
            submitForm() {
                if(this.participant.checkout_date < this.participant.checkin_date) {
                    this.$toast.open({
                        message: 'Check out date can not be earlier than check in date', 
                        type: 'error'
                    });
                    return;
                }
                const participantData = new FormData();
                Object.keys(this.participant).forEach(item => {
                    participantData.append("" + item, this.participant[item]);
                });
                this.addParticipant(participantData);
                this.$refs.abstractFile.value = "";
                this.participant = {
                    title: '',
                    name: '',
                    university: '',
                    topic: '',
                    abstract_title: '',
                    abstract_file: '',
                    surname: '',
                    phone_number: '',
                    department: '',
                    presentation_type: '',
                    accomodation_type: '',
                    room_type: '',
                    checkin_date: '',
                    checkout_date: '',
                    person_1: '',
                    person_2: '',
                    person_3: '',
                    status: false,
                    email: ''
                }
            }
        },
        components: {
            InputSelect,
            Input
        },
          computed: {
      ...mapGetters(['textsByPage'])
    },
        mounted() {
        this.getTextsByPage('Register')
        }
    }
</script>