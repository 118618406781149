<template>
  <div class="header">
    <div class="header-inner container-fluid flex align-center justify-between">
      <div class="logo">
        <router-link to="/" exact>
          <img src="@/assets/logoup.png" alt="Universiteti i Prishtines" title="University of Prishtina" class="logo-img">
          <img src="@/assets/ilirias.png" alt="Universiteti i Prishtines" title="Ilirias" class="logo-img">
          <span>FWBCMA 10-12 June 2021, Prishtina- Kosovo</span>
        </router-link>
      </div>
      <nav class="header-navigation" :class="{ 'navigation-open': menuShown }">
        <ul class="flex align-center justify-end">
          <li @click="handleClick">
            <router-link class="flex align-center normal" to="/" exact>
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.462 11.8034L13.6488 0.262835C13.5637 0.179517 13.4627 0.113416 13.3515 0.0683154C13.2402 0.0232147 13.121 0 13.0006 0C12.8802 0 12.761 0.0232147 12.6497 0.0683154C12.5385 0.113416 12.4375 0.179517 12.3524 0.262835L0.539241 11.8034C0.195083 12.1398 6.10352e-05 12.5968 6.10352e-05 13.0735C6.10352e-05 14.0632 0.82317 14.8679 1.83557 14.8679H3.08027V23.1028C3.08027 23.5991 3.49039 24 3.99802 24H11.1651V17.7194H14.3772V24H22.0032C22.5108 24 22.9209 23.5991 22.9209 23.1028V14.8679H24.1656C24.6532 14.8679 25.1207 14.6801 25.4648 14.3408C26.179 13.6399 26.1789 12.5043 25.462 11.8034Z"
                  fill="black"
                />
              </svg>
              Home
            </router-link>
          </li>
          <li @click="handleClick">
            <router-link class="flex align-center normal" to="/conference">
              <svg
                width="16"
                height="24"
                viewBox="0 0 16 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.598 0.011638L15.598 2.41171C15.7113 2.43889 15.8133 2.51232 15.8866 2.61952C15.9598 2.72672 15.9999 2.86107 16 2.99973V21.0003C15.9999 21.1389 15.9598 21.2733 15.8866 21.3805C15.8133 21.4877 15.7113 21.5611 15.598 21.5883L6.598 23.9884C6.52549 24.0058 6.45067 24.0036 6.37892 23.9821C6.30718 23.9606 6.2403 23.9203 6.1831 23.8641C6.1259 23.8078 6.07981 23.7371 6.04815 23.6569C6.01649 23.5767 6.00004 23.4891 6 23.4003V0.599656C6.00004 0.510918 6.01649 0.423297 6.04815 0.343106C6.07981 0.262914 6.1259 0.192148 6.1831 0.135907C6.2403 0.0796647 6.30718 0.0393471 6.37892 0.0178586C6.45067 -0.00362991 6.52549 -0.00575455 6.598 0.011638ZM5 2.39971V21.6003H0.75C0.568762 21.6003 0.393658 21.5215 0.25707 21.3786C0.120481 21.2356 0.0316483 21.0381 0.00699997 20.8227L0 20.7003V3.29974C7.6429e-06 3.08225 0.0656429 2.87211 0.184767 2.7082C0.303892 2.54429 0.468446 2.43769 0.648 2.40811L0.75 2.39971H5ZM9 10.8C8.73478 10.8 8.48043 10.9264 8.29289 11.1514C8.10536 11.3765 8 11.6817 8 12C8 12.3183 8.10536 12.6235 8.29289 12.8486C8.48043 13.0736 8.73478 13.2 9 13.2C9.26522 13.2 9.51957 13.0736 9.70711 12.8486C9.89464 12.6235 10 12.3183 10 12C10 11.6817 9.89464 11.3765 9.70711 11.1514C9.51957 10.9264 9.26522 10.8 9 10.8Z"
                  fill="black"
                />
              </svg>
              Conference
            </router-link>
          </li>
          <li @click="handleClick">
            <router-link class="flex align-center normal" to="/proceeding">
              <svg
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3893 4.8H8.3938C7.75776 4.8 7.14777 5.05286 6.69802 5.50294C6.24827 5.95303 5.99561 6.56348 5.99561 7.2V18H2.39832C1.76227 18 1.15228 17.7471 0.702537 17.2971C0.252788 16.847 0.00012207 16.2365 0.00012207 15.6V2.4C0.00012207 1.76348 0.252788 1.15303 0.702537 0.702944C1.15228 0.252856 1.76227 0 2.39832 0H11.9911C12.6271 0 13.2371 0.252856 13.6869 0.702944C14.1366 1.15303 14.3893 1.76348 14.3893 2.4V4.8ZM9.5929 6H17.9866L21.5839 9.6V21.6C21.5839 22.2365 21.3312 22.847 20.8814 23.2971C20.4317 23.7471 19.8217 24 19.1857 24H9.5929C8.95685 24 8.34687 23.7471 7.89712 23.2971C7.44737 22.847 7.1947 22.2365 7.1947 21.6V8.4C7.1947 7.76348 7.44737 7.15303 7.89712 6.70294C8.34687 6.25286 8.95685 6 9.5929 6Z"
                  fill="black"
                />
              </svg>
              Proceeding
            </router-link>
          </li>
          <li @click="handleClick">
            <router-link class="flex align-center normal" to="/venue">
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.6365 5.14287V6.00001C25.6365 6.11367 25.5915 6.22268 25.5113 6.30306C25.4312 6.38343 25.3225 6.42858 25.2092 6.42858H23.9274V7.07144C23.9274 7.42646 23.6404 7.71429 23.2865 7.71429H2.35012C1.99618 7.71429 1.70921 7.42646 1.70921 7.07144V6.42858H0.427395C0.314075 6.42858 0.205396 6.38343 0.125267 6.30306C0.0451381 6.22268 0.00012207 6.11367 0.00012207 6.00001V5.14287C0.000122681 5.05808 0.0251965 4.9752 0.072171 4.90471C0.119145 4.83422 0.18591 4.77929 0.264016 4.74687L12.6549 0.0325859C12.7595 -0.010862 12.8771 -0.010862 12.9817 0.0325859L25.3726 4.74687C25.4507 4.77929 25.5175 4.83422 25.5644 4.90471C25.6114 4.9752 25.6365 5.05808 25.6365 5.14287ZM24.3547 21.4286H1.28194C0.574003 21.4286 0.00012207 22.0042 0.00012207 22.7143V23.5714C0.00012207 23.6851 0.0451381 23.7941 0.125267 23.8745C0.205396 23.9548 0.314075 24 0.427395 24H25.2092C25.3225 24 25.4312 23.9548 25.5113 23.8745C25.5915 23.7941 25.6365 23.6851 25.6365 23.5714V22.7143C25.6365 22.0042 25.0626 21.4286 24.3547 21.4286ZM4.27285 8.57144V18.8571H2.35012C1.99618 18.8571 1.70921 19.145 1.70921 19.5V20.5714H23.9274V19.5C23.9274 19.145 23.6404 18.8571 23.2865 18.8571H21.3637V8.57144H17.9456V18.8571H14.5274V8.57144H11.1092V18.8571H7.69103V8.57144H4.27285Z"
                  fill="black"
                />
              </svg>
              Venue
            </router-link>
          </li>
          <li @click="handleClick">
            <router-link class="flex align-center normal" to="/participants">
              <svg
                width="32"
                height="24"
                viewBox="0 0 32 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 24C14 24 12 24 12 22C12 20 14 14 22 14C30 14 32 20 32 22C32 24 30 24 30 24H14ZM22 12C23.5913 12 25.1174 11.3679 26.2426 10.2426C27.3679 9.11742 28 7.5913 28 6C28 4.4087 27.3679 2.88258 26.2426 1.75736C25.1174 0.632141 23.5913 0 22 0C20.4087 0 18.8826 0.632141 17.7574 1.75736C16.6321 2.88258 16 4.4087 16 6C16 7.5913 16.6321 9.11742 17.7574 10.2426C18.8826 11.3679 20.4087 12 22 12Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.4321 24C10.1356 23.3756 9.98773 22.6911 10.0001 22C10.0001 19.29 11.3601 16.5 13.8721 14.56C12.6183 14.1737 11.312 13.9847 10.0001 14C2.00011 14 0.00012207 20 0.00012207 22C0.00012207 24 2.00011 24 2.00011 24H10.4321Z"
                  fill="black"
                />
                <path
                  d="M9.00012 12C10.3262 12 11.598 11.4732 12.5357 10.5355C13.4733 9.59785 14.0001 8.32608 14.0001 7C14.0001 5.67392 13.4733 4.40215 12.5357 3.46447C11.598 2.52678 10.3262 2 9.00012 2C7.67404 2 6.40227 2.52678 5.46459 3.46447C4.52691 4.40215 4.00012 5.67392 4.00012 7C4.00012 8.32608 4.52691 9.59785 5.46459 10.5355C6.40227 11.4732 7.67404 12 9.00012 12Z"
                  fill="black"
                />
              </svg>
              Participants
            </router-link>
          </li>
          <li @click="handleClick">
            <router-link class="flex align-center normal" to="/contact">
              <svg
                width="21"
                height="24"
                viewBox="0 0 21 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2001 0C4.57203 0 0 4.572 0 10.2C0 15.828 4.57203 20.4 10.2001 20.4H10.8001V24C16.6321 21.192 20.4001 15.6 20.4001 10.2C20.4001 4.572 15.8281 0 10.2001 0ZM11.4001 17.4H9.00006V15H11.4001V17.4ZM11.4001 13.2H9.00006C9.00006 9.3 12.6001 9.6 12.6001 7.2C12.6001 5.88 11.5201 4.8 10.2001 4.8C8.88006 4.8 7.80006 5.88 7.80006 7.2H5.40004C5.40004 4.548 7.54805 2.4 10.2001 2.4C12.8521 2.4 15.0001 4.548 15.0001 7.2C15.0001 10.2 11.4001 10.5 11.4001 13.2Z"
                  fill="black"
                />
              </svg>
              Contact
            </router-link>
          </li>
        </ul>
      </nav>
      <button
        class="hamburger-menu"
        :class="{ 'hamburger-active': menuShown }"
        @click="toggleMobileMenu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

    &-inner {
      min-height: 100px;
      @include for-tablet-landscape {
        min-height: 80px;
      }
      .header-navigation {
        @include for-tablet-landscape {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          background: #fff;
          height: 100vh;
          width: 100%;
          max-width: 100%;
          justify-content: center;
          z-index: 5;
          display: none;
          &.navigation-open {
            display: flex;
          }
        }
        ul {
          @include for-tablet-landscape {
            flex-direction: column;
            height: 100%;
            align-items: stretch;
            justify-content: center;
            display: inline-flex;
          }
          li {
            a {
              transition: color .3s ease;
              @include for-laptop-min {
                font-size: 14px;
                svg {
                  margin-right: 10px;
                  transform: scale(0.9);
                }
              }
              @include for-tablet-landscape {
                font-size: 18px;
                svg{
                  min-width: 40px;
                }
              }

              svg {
                display: inline-block;
                margin-right: 15px;
                path {
                  transition: fill .3s ease;
                }
              }
              font-size: 16px;
              line-height: 19px;

              &.router-link-active {
                color: $primary;
                svg {
                  path {
                    fill: $primary;
                  }
                }
              }
              &:hover {
                color: $primary;
                svg {
                  path {
                    fill: $primary;
                  }
                }
              }
            }

            &:not(:last-child) {
              margin-right: 80px;
              @include for-laptop-min {
                margin-right: 45px;
              }
              @include for-tablet-landscape {
                margin-right: 0;
                margin-bottom: 40px;
              }
            }
          }
        }
      }
      .hamburger-menu {
        display: none;
        width: 3rem;
        height: 2.4rem;
        outline: none;
        border: none;
        position: absolute;
        right: 30px;
        top: 30px;
        background-color: transparent;
        z-index: 6;

        @include for-tablet-landscape {
          display: block;
        }

        span {
          display: inline-block;
          width: 100%;
          height: 2.5px;
          background-color: $secondary;
          position: absolute;
          transition: all 0.6s ease;

          &:first-child {
            top: 0;
            left: 0;
          }

          &:nth-child(2) {
            top: 8px;
            left: 0;
          }

          &:nth-child(3) {
            top: 16px;
            left: 0;
          }
        }

        &.hamburger-active {
          position: fixed;
          span {
            &:first-child {
              top: 8px;
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              display: none;
            }
            &:nth-child(3) {
              top: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
</style>

<script>
  export default {
    name: 'Header',
    data() {
      return {
        menuShown: false,
      };
    },
    methods: {
      toggleMobileMenu() {
        this.menuShown = !this.menuShown;
      },
      handleClick() {
        if(this.menuShown) this.toggleMobileMenu();
      },
    },
  };
</script>
