var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rich-text"},[_c('editor',{attrs:{"api-key":"f20rlklg3b5ybqb6tl6xqn89k13xrm62sb2dyiz69uebjszb","initial-value":_vm.value,"init":{
      height: 269,
      menubar: false,
      branding: false,
      placeholder: _vm.label,
      toolbar_location: 'bottom',
      resize: false,
      skin: 'naked',
      statusbar: false,
      plugins: [
        'advlist autolink lists link preview anchor',
        'code fullscreen',
        'paste code' ],
      toolbar:
        'undo redo formatselect bold italic backcolor \
          alignleft aligncenter alignright alignjustify \
          bullist numlist outdent indent',
    }},on:{"input":function($event){return _vm.updateValue($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }