<template>
  <div class="committees">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteCommittee"/>
    <div class="flex align-center justify-between committees-head">
      <h3>Committees</h3>
      <router-link to="/admin/committees/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Committee</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Title</th>
          <th class="text-left">Position</th>
          <th class="text-left">Country</th>
          <th class="text-left">Category</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!committeesWithCategories.length && !loading"><td class="text-left semibold pl-3" colspan="4">No committees were found. Please add some!</td></tr>
        <tr v-for="(committee, index) in committeesWithCategories" :key="committee._id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left semibold">{{ committee.title }}</td>
          <td class="text-left">{{ committee.position }}</td>
          <td class="text-left">{{ committee.country }}</td>
          <td class="text-left">{{ committee.category }}</td>
          <td class="text-center">
            <router-link :to="`/admin/committees/${committee._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="committeeDeletion(committee)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!committeesWithCategories.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .committees {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 300% !important;
      }
      @include for-phone-only {
        width: 200%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';
  
  export default {
    name: 'Committees',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getCommittees();
      this.getCategories();
    },
    computed: {
      ...mapGetters(['committees', 'categories','loading']),
      committeesWithCategories() {
        return this.committees.map(committee => {
          const category = this.categories.find(item => item._id == committee.category);
          const newCommittee = {...committee};
          newCommittee.category = category?.title;
          return newCommittee;
        });
      }
    },
    methods: {
      ...mapActions(['getCommittees', 'deleteCommittee', 'getCategories']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      committeeDeletion(committee) {
        this.deleteShownModal = true;
        this.deletion_id = committee;
      },
      onDeleteCommittee() {
        this.deleteCommittee(this.deletion_id);
        this.deleteShownModal = false;
      },
    },
    components: {
      DeleteModal,
    },
  };
</script>
