<template>
  <div class="add-view add-text">
    <div class="breadcrumb">
      <router-link to="/admin/texts">Texts</router-link>
      <img src="@/assets/icon-back-arrow-right.svg" />
      <span>Add Text</span>
    </div>
    <h2 class="add-title">Add Text</h2>
    <div class="add-form-container">
      <form @submit.prevent="submitForm">
        <div class="input-container">
          <label class="bold" for="title">Title</label>
          <input
            type="text"
            placeholder="Enter title"
            name="title"
            required
            id="title"
            v-model="text.title"
          />
        </div>
        <div class="input-container">
          <label class="bold" for="Content">Content</label>
          <RichText label="Enter content in here" name="content" value="Content edited" v-model="text.content"/>
        </div>
        <InputSelect
          label="Page"
          placeholder="Home"
          color="white"
          class="mb-3"
          :options="['Home', 'Venue', 'Register', 'Proceeding']"
          :fromBack="true"
          :selected="text.page"
          @change="text.page = $event"
        />
        <button type="submit" class="primary-back-btn">
          Save Text
        </button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .add-form-container {
    width: 50%;
  }
  .input-container {
    margin-bottom: 30px;
    width: 70rem !important;
    input {
      width: 70rem !important;
    }
  }
</style>

<script>
  import RichText from '@/components/back/RichText.vue';
  import InputSelect from '@/components/shared/InputSelect.vue';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'EditText',
    mounted() {
      this.getText(this.$route.params.id);
    },
    computed: {
      ...mapGetters(['text'])
    },
    methods: {
      ...mapActions(['getText', 'editText']),
      submitForm() {
        this.editText(this.text);
      }
    },
    components: {
      RichText,
      InputSelect,
    },
  };
</script>
