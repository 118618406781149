<template>
  <div class="home-page page-max-text main-spacing flex justify-between">
    <div class="text-container">
      <div class="text" v-for="text in textsByPage" :key="text._id">
        <h2 class="main-title">{{ text.title }}</h2>
        <div class="text-content mt-3" v-html="text.content">
        </div>
      </div>
    </div>
    <Sidebar :showFiles="true" :showVenue="true" :showSponsors="true" :chairs="true" />
  </div>
</template>

<style lang="scss" scoped></style>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Sidebar from '@/components/shared/Sidebar.vue';
  
  export default {
    name: 'Home',
    components: {
      Sidebar,
    },
    methods: {
      ...mapActions(['getTextsByPage'])
    },
    computed: {
      ...mapGetters(['textsByPage'])
    },
    mounted() {
      this.getTextsByPage('Home')
    }
  };
</script>
