<template>
  <div class="texts">
    <DeleteModal v-if="deleteModalShown" :click="closeDeleteModal" @onDelete="onDeleteText"/>
    <div class="flex align-center justify-between texts-head">
      <h3>Text</h3>
      <router-link to="/admin/texts/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Text</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Title</th>
          <!-- <th class="text-left">Content</th> -->
          <th class="text-left">Page</th>
          <th class="text-left">Actions</th>
        </tr>
        <tr v-if="!texts.length && !loading"><td class="text-left semibold pl-3" colspan="4">No texts were found. Please add some!</td></tr>
        <tr v-if="!texts.length && loading"><td class="text-center semibold pl-3 loading" colspan="4"><Loading /></td></tr>
        <tr v-for="(text, index) in texts" :key="text._id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left semibold">{{text.title ? text.title : 'NO TITLE'}}</td>
          <!-- <td class="text-left" v-html="text.content">
          </td> -->
          <td class="text-left">{{text.page}}</td>
          <td class="text-left">
            <router-link :to="`/admin/texts/${text._id}`"
              ><img src="@/assets/icon-eye.svg" alt=""/></router-link
            ><router-link :to="`/admin/texts/${text._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="textDeletion(text)"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .texts {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      overflow: auto;
      @include for-small-phone-only {
        width: 300% !important;
      }
      @include for-tablet-landscape {
        width: 200%;
      }
      tr {
        // > th:nth-child(2){
        //   width: 100%;
        //   @include for-tablet-landscape {
        //     width: 200px;
        //   }
        // }
        // > th:nth-child(1){
        //   width: 50px;
        // }
        // > th:nth-child(3){
        //   width: 200px;
        // }
      }
      tr {
        // > td:nth-child(3) {
        //   width: 500px;
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
        //   overflow: hidden;
        //   display: inline-block;
        //   line-height: 28px;
        //   & * {
        //     width: 500px;
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     overflow: hidden;
        //   }
        //   @include for-tablet-landscape {
        //     width: 200px;
        //   }
        // }
        // > td:nth-child(1){
        //   width: 50px;
        // }
        // > td:nth-child(2){
        //   width: 100%;
        // }
        // > td:nth-child(3){
        //   width: 200px;
        // }
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';

  export default {
    name: 'Texts',
    data() {
      return {
        deleteModalShown: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getTexts();
    },
    computed: {
      ...mapGetters(['texts', 'loading'])
    },
    methods: {
      ...mapActions(['getTexts', 'deleteText']),
      closeDeleteModal() {
        this.deleteModalShown = false;
      },
      textDeletion(text) {
        this.deleteModalShown = true;
        this.deletion_id = text;
      },
      onDeleteText() {
        this.deleteText(this.deletion_id);
        this.deleteModalShown = false;
      }
    },
    components: {
      DeleteModal,
    },
  };
</script>
