<template>
  <div class="sidebar inline-flex flex-column">
    <div
      v-if="showFiles"
      class="flex flex-column align-start mb-3 px-3 py-2 rounded-20 sidebar-item"
    >
      <h2 class="semibold">Files</h2>
      <div v-for="file in files" :key="file._id" class="mt-1 align-center justify-between flex full-width">
        <span>{{ file.title }}</span>
        <a :href="file.file" download target="_blank" class="download-btn"><img src="@/assets/icon-download.svg" alt=""/></a>
      </div>
    </div>
    <div
      v-if="showVenue"
      class="flex flex-column align-start mb-3 px-3 py-2 rounded-20 sidebar-item"
    >
      <h2 class="semibold">Venue</h2>
      <div class="mt-1 align-center flex full-width">
        <span>{{ venue.title }}</span>
      </div>
    </div>
    <div
      v-if="showSponsors"
      class="flex flex-column align-start mb-3 px-3 py-2 rounded-20 sidebar-item"
    >
      <h2 class="semibold">Sponsors</h2>
      <div v-for="sponsor in sponsors" :key="sponsor._id" class="mt-1 align-center justify-between flex full-width">
        <a :href="sponsor.link" target="_blank">{{ sponsor.title }}</a>
        <img class="sponsor-photo" :src="sponsor.photo" :alt="sponsor.title">
      </div>
    </div>
    <div v-if="chairs">
      <div
      v-for="chairCategory in categories"
      :key="chairCategory._id"
    >
      <div v-if="categoriesWithCommittees(chairCategory.committees) && categoriesWithCommittees(chairCategory.committees).length" class="flex flex-column align-start mb-3 px-3 py-2 rounded-20 sidebar-item">
        <h2 class="semibold">{{ chairCategory.title }} Chair</h2>
        <div v-for="(chair,index) in categoriesWithCommittees(chairCategory.committees)" :key="index" class="mt-1 align-start justify-between flex full-width flex-column">
          <span>{{chair.title}}</span>
          <p class="light">{{chair.position}}</p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .sidebar {
    @include for-tablet-landscape{
      margin-top: 50px;
      align-items: center;
    }
    &-item {
      background: rgba($primary, 0.1);
      width: 35rem;
      @include for-tablet-landscape {
        width: 100%;
        max-width: 35rem;
      }
      .sponsor-photo {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        object-fit: cover;
      }
      h2 {
        font-size: 18px;
      }
      span, a {
        font-size: 16px;
        font-family: $font2;
        font-weight: 300;
        line-height: 26px;
      }
      p {
        color: #919191;
        font-size: 14px;
        margin-top: 5px;
      }
    }
    .download-btn {
      transition: transform .3s ease;
      display: inline-block;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Sidebar',
    props: ['showFiles', 'showVenue', 'showSponsors', 'chairs'],
    mounted() {
      this.getFiles();
      this.getVenue();
      this.getSponsors();
      this.getCategories();
      this.getCommittees();
    },
    computed: {
      ...mapGetters(['files', 'venue', 'sponsors', 'categories', 'committees']),
    },
    methods: {
      ...mapActions(['getFiles', 'getVenue', 'getSponsors', 'getCommittees', 'getCategories']),
      categoriesWithCommittees(chairCategory) {
        if(chairCategory) {
          const res = this.committees.filter(committee => chairCategory.includes(committee._id)).filter(committee => committee.chair === true);
          return res;
        }
      }
    },
  };
</script>
