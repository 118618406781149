<template>
  <div class="importantDates">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteImportantDate"  />
    <div class="flex align-center justify-between importantDates-head">
      <h3>Important Dates</h3>
      <router-link to="/admin/important-dates/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Date</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Title</th>
          <th class="text-left">Date</th>
          <th class="text-center">Actions</th>
        </tr>
         <tr v-if="!importantDates.length && !loading"><td class="text-left semibold pl-3" colspan="4">No important dates were found. Please add some!</td></tr>
        <tr v-for="(importantDate, index) in importantDates" :key="importantDate._id">
          <td class="text-center">{{ index + 1}}</td>
          <td class="text-left semibold">{{ importantDate.title }}</td>
          <td class="text-left">
            <img src="@/assets/icon-date.svg" alt="" /> {{ importantDate.date }}
          </td>
          <td class="text-center">
            <router-link :to="`/admin/important-dates/${importantDate._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
             @click.stop="importantDateDeletion(importantDate)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!importantDates.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .importantDates {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 150%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
        margin-bottom: -4px;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';

  export default {
    name: 'ImportantDates',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getImportantDates();
    },
    computed: {
      ...mapGetters(['importantDates','loading']),
    },
      methods: {
      ...mapActions(['getImportantDates', 'deleteImportantDate']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      importantDateDeletion(importantDate) {
        this.deleteShownModal = true;
        this.deletion_id = importantDate;
      },
      onDeleteImportantDate() {
        this.deleteImportantDate(this.deletion_id);
        this.deleteShownModal = false;
      }
    },
    components: {
      DeleteModal,
    },
  };
</script>
