import router from '@/router';

export default {
    state: {
        categories: [{
            _id: '',
            title: ''
        }],
        categoryCommittees: [],
        category: {}
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },
        addCategory(state, category) {
            state.categories = [...state.categories, category];
        },
        setCategory(state, category) {
            state.category = category;
        },
        setCategoryCommittees(state, committees) {
            state.categoryCommittees = committees;
        }
    },
    actions: {
        getCategories({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('categories')
                .then(({ data }) => {
                    commit('setCategories', data.categories);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addCategory({ commit }, category) {
            this._vm.$axios.post('categories', category)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addCategory', data.category);
                    router.push('/admin/categories');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getCategory({ commit }, category_id) {
            this._vm.$axios.get(`categories/${category_id}`)
                .then(({ data }) => {
                    commit('setCategory', data.category);
                })
                .catch(err => console.log(err));
        },
        editCategory({ commit }, category) {
            this._vm.$axios.put(`categories/${category._id}`, category)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/categories');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteCategory({ commit, getters }, category) {
            this._vm.$axios.delete(`categories/${category._id}`)
                .then(({ data }) => {
                    const categories = getters.categories.filter(item => item._id != category._id);
                    commit('setCategories', categories);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        },
        getCategoryCommittees({ commit, getters }, category_id) {
            this._vm.$axios.get(`categories/${category_id}/committees`)
                .then(({ data }) => {
                    commit('setCategoryCommittees', data.committees);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        categories(state) {
            return state.categories;
        },
        category(state) {
            return state.category;
        },
        categoryCommittees(state) {
            return state.categoryCommittees;
        }
    }
}