<template>
  <div class="speakers grid-3">
    <div class="speaker flex align-center" v-for="(speaker,i) in speakers" :key="i">
      <div
        class="speaker-profile no-shrink rounded bg-normal mr-3"
        :style="`background-image: url(${speaker.photo}`"
      ></div>
      <div class="speaker-info">
        <h3 class="extrabold secondary-color mb-1">{{ speaker.title }}</h3>
        <p>{{ speaker.university }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.speakers {
  @include for-tablet-landscape {
    grid-template-columns: 1fr 1fr;
  }
  @include for-phone-only {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.speaker {
  @include for-phone-only {
    flex-direction: column;
    &-profile {
      margin-right: 0 !important;
    }
    &-info {
      margin-top: 20px;
      text-align: center;
    }
  }
  &-profile {
    width: 8.6rem;
    height: 8.6rem;
  }
  &-info {
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
      color: #737373;
      line-height: 20px;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Speakers",
  mounted() {
    this.getSpeakers();
  },
  computed: {
    ...mapGetters(["speakers"]),
  },
  methods: {
    ...mapActions(["getSpeakers", "deleteSpeaker"]),
  },
};
</script>
