<template>
  <div class="delete-modal flex align-center justify-center">
    <div class="delete-modal--inner" v-click-outside="click">
      <h2 class="secondary-title bold">Are you sure you want to delete this?</h2>
      <div class="flex btn-container align-items justify-center mt-2">
        <button class="primary-btn mr-2 py-1 px-2" @click="onDelete">Yes</button>
        <button class="primary-btn py-1 px-2" @click="click">Cancel</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .delete-modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 !important;
    z-index: 50;

  &--inner {
    // width: 450px;
    padding: 40px;
    background: #fff;
    border-radius: 15px;
    margin : 0 20px;
    h2 {
      font-size: 18px;
    }

      .btn-container {
        > button:first-child {
          background-color: red !important;
        }
      }
    }
  }
</style>

<script>
  export default {
    name: 'DeletModal',
    props: ['click'],
    methods: {
      onDelete() {
        this.$emit('onDelete')
      }
    }
  };
</script>
