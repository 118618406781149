<template>
    <div class="committee mt-4">
        <ul class="main-list">
            <li 
                v-for="committee in categoryCommittees"
                :key="committee._id"
                class="main-list-item"
            >{{ committee.title }}, {{ committee.position }}, {{ committee.country }}</li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    .committee {
        @include for-phone-only {
            margin-top: 20px;
        }
        .main-list{   
            @include for-phone-only {
                padding-left: 35px;
            }
        }
    }
</style>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Committee',
        mounted() {
            this.getCategoryCommittees(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['categoryCommittees'])
        },
        methods: {
            ...mapActions(['getCategoryCommittees', 'getCategories'])
        },
        watch: {
            '$route.params.id': function (id) {
                this.getCategoryCommittees(id);
            }
        },
    }
</script>