import router from '@/router';

export default {
    state: {
        participants: [],
        participant: {}
    },
    mutations: {
        setParticipants(state, participants) {
            state.participants = participants;
        },
        addParticipant(state, participant) {
            state.participants = [...state.participants, participant];
        },
        setParticipant(state, participant) {
            state.participant = participant;
        }
    },
    actions: {
        getParticipants({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('participants')
                .then(({ data }) => {
                    commit('setParticipants', data.participants);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addParticipant({ commit }, participant) {
            this._vm.$axios.post('participants', participant)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addParticipant', data.participant);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getParticipant({ commit }, participant_id) {
            this._vm.$axios.get(`participants/${participant_id}`)
                .then(({ data }) => {
                    commit('setParticipant', data.participant);
                })
                .catch(err => console.log(err));
        },
        deleteParticipant({ commit, getters }, participant) {
            this._vm.$axios.delete(`participants/${participant._id}`)
                .then(({ data }) => {
                    const participants = getters.participants.filter(item => item._id != participant._id);
                    commit('setParticipants', participants);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        },
        changeStatus({ commit, getters }, participant_id) {
            this._vm.$axios.get(`participants/${participant_id}/status`)
                .then(({ data }) => {
                    getters.participants.map(item => {
                        if(item._id == participant_id) {
                            item.status = true
                        }
                    })
                    const participant = getters.participant;
                    participant.status = true;
                    commit('setParticipant', participant);
                })
        }
    },
    getters: {
        participants(state) {
            return state.participants;
        },
        participant(state) {
            return state.participant;
        }
    }
}