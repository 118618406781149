<template>
    <div class="contact">
        <div class="contact-head flex align-center justify-between">
            <h3>Contact info</h3>
            <router-link to="/admin/contacts" class="primary-back-btn">Go back</router-link>
        </div>
        <div class="contact-body">
            <h4>Name</h4>
            <p>{{ contact.name }}</p>
            <h4>Email</h4>
            <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
            <h4>Phone</h4>
            <a :href="`tel:${contact.phone_number}`">{{ contact.phone_number }}</a>
            <h4 class="bold">Content</h4>
            <p>{{ contact.message }}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .contact {
        &-head {
            margin-bottom: 25px;
            h3 {
                font-size: 24px;
            }
        }
        &-body {
            background: #fff;
            padding: 32px;
            h4 {
                font-size: 18px;
                color: #223345;
                margin-bottom: 15px;
            }
            p {
                font-size: 14px;
                color: #223345;
                width: 50%;
                margin-bottom: 20px;
                word-break: break-word;
            }
            a {
                color: #11A1FD;
                font-size: 14px;
                margin-bottom: 20px;
                display: inline-block;
            }
        }
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'Contact',
        mounted() {
            this.getContact(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['contact'])
        },
        methods: {
            ...mapActions(['getContact'])
        }
    };
</script>