<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/categories">Categories</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Add Category</span>
        </div>
        <h2 class="add-title">Add category</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="input-container">
                    <label class="bold" for="title">Category</label>
                    <input type="text" placeholder="Enter category" name="title" v-model="title" required id="title">
                </div>
                <button type="submit" class="primary-back-btn">Add Category</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .input-container {
        margin-bottom: 30px;
    }
</style>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'AddCategory',
        data() {
            return {
                title: ''
            }
        },
        methods: {
            ...mapActions(['addCategory']),
            submitForm() {
                this.addCategory({
                    title: this.title
                });
            }
        }
    }
</script>