import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `https://fe-nproject.herokuapp.com/api/`,
});

if (window.localStorage.nprojectToken) {
  const token = JSON.parse(window.localStorage.getItem('nprojectToken'));
  if (token) {
    axiosInstance.defaults.headers.common['auth-token'] = token;
  } else {
    axiosInstance.defaults.headers.common['auth-token'] = '';
  }
}

export default {
  install: function(Vue) {
    Vue.prototype.$axios = axiosInstance;
  },
};
