<template>
  <div class="page">
    <div v-if="loadingImages" class="loading-full">
      <Loading />
    </div>
    <Header />
    <Slider />
    <div class="container">
      <transition name="fadepage" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
  .loading-full {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    backdrop-filter: blur(20px);
    z-index: 10;
  }
</style>

<script>
  import Header from '@/components/shared/Header.vue';
  import Slider from '@/components/shared/Slider.vue';
  import Footer from '@/components/shared/Footer.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: "BasePage",
    components: {
      Header,
      Slider,
      Footer,
    },
    computed: {
      ...mapGetters(['loadingImages'])
    }
  };
</script>
