<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/important-dates">Important Dates</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Edit Important Date</span>
        </div>
        <h2 class="add-title">Edit important date</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="input-container">
                    <label class="bold" for="title">Title</label>
                    <input required type="text" placeholder="Enter title" name="title" id="title" v-model="importantDate.title">
                </div>
                <div class="input-container">
                    <label class="bold" for="date">Date</label>
                    <input required type="text" placeholder="Enter date" name="date" id="date" v-model="importantDate.date">
                </div>
                <button type="submit" class="primary-back-btn">Save date</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .input-container {
        margin-bottom: 30px;
    }
</style>
<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'EditImportantDate',
        mounted() {
            this.getImportantDate(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['importantDate']),
        },
        methods: {
            ...mapActions(['getImportantDate', 'editImportantDate']),
            submitForm() {
                this.editImportantDate(this.importantDate);
            }
        }
    }
</script>