<template>
  <div class="profile flex align-center" @click.stop="toggleDropDown">
    <span class="profile-name">{{ loggedUser.name }}</span>
    <img :src="require('../../assets/icon-back-arrow-right.svg')" alt="" />
    <div v-if="dropDownShown" class="dropdown" v-click-outside="toggleDropDown">
      <router-link to="/">Home</router-link>
      <div @click="logout">Log out</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .profile {
    cursor: pointer;
    position: relative;
    margin-right: 40px;
    @include for-phone-only {
      margin-right: 0;
    }
    &-name {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin-right: 8px;
    }
    img {
      transform: rotate(90deg);
    }
    .dropdown {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      background-color: #fff;
      z-index: 3;
      width: 200%;
      box-shadow: 1px 3px 40px rgba(#000, 0.1);
      > * {
        font-size: 14px;
        font-weight: 600;
        padding: 15px 20px;
        display: inline-block;
        width: 100%;
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Profile',
    data() {
      return {
        dropDownShown: false,
      };
    },
    computed: {
      ...mapGetters(['loggedUser']),
    },
    methods: {
      ...mapActions(['logout']),
      toggleDropDown() {
        this.dropDownShown = !this.dropDownShown;
      },
    },
  };
</script>
