<template>
    <div class="committees">
        <div class="tabs inline-flex">
            <router-link v-for="category in categories" :key="category._id" class="normal secondary-color pr-1 mr-8" :to="`/conference/committees/${category._id}`">{{ category.title }}</router-link>
        </div>
        <div class="committee-item-content">
            <transition name="fadepage" mode="out-in">
                <router-view />
            </transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .committees {
        overflow: hidden;
        position:relative;
        .tabs {
            border-bottom: 1px solid #c4c4C4;
            @include for-phone-only{
                overflow: scroll hidden;
                display: flex;
                // position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    height:30px;
                    width: 50px;
                    background-image: linear-gradient(
                       to left,
                       rgba(#fff, 1),
                       rgba(#fff, 0)
                    );
                    pointer-events: none;
                }
                &::-webkit-scrollbar {
                   width: 0; 
                   display: none;
                   background: transparent; 
                }
            }
            a {
                font-size: 18px;
                line-height: 22px;
                padding-bottom: 15px;
                @include for-phone-only{
                    white-space: nowrap;
                    font-size: 16px;
                    margin-right: 30px !important;
                }
                &.router-link-active {
                    color: $primary !important;
                    position: relative;
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 3px;
                        bottom: -1px;
                        left: 0;
                        background: $primary;
                        border-radius: 5px 5px 0px 0px;
                    }
                }
                &:last-child {
                    margin-right: 0px !important;
                }
            }
        }
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'Committees',
        mounted() {
            this.getCategories();
        },
        computed: {
            ...mapGetters(['categories'])
        },
        methods: {
            ...mapActions(['getCategories'])
        },
        watch: {
            categories: function() {
                if(!this.$route.params.id) {
                    this.$router.push(`/conference/committees/${this.categories[0]._id}`)
                }
            }
        }
    }
</script>