<template>
  <div class="proceeding-page page-max-text main-spacing flex justify-between">
    <div class="text-container">
      <div class="text" v-for="text in textsByPage" :key="text._id">
        <h2 class="main-title">{{ text.title }}</h2>
        <div class="text-content mt-3" v-html="text.content">
        </div>
      </div>

      <ul class="main-list mt-2">
        <li class="main-list-item" v-for="specialIssue in specialIssues" :key="specialIssue._id">
          <a :href="specialIssue.link" target="_blank">{{ specialIssue.title }}</a>
        </li>
      </ul>
    </div>
    <Sidebar :showFiles="true" :showVenue="true" :showSponsors="true" />
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Sidebar from '@/components/shared/Sidebar.vue';
  
  export default {
    name: 'Proceeding',
    components: {
      Sidebar,
    },
      methods: {
      ...mapActions(['getTextsByPage', 'getSpecialIssues'])
    },
    computed: {
      ...mapGetters(['textsByPage', 'specialIssues'])
    },
    mounted() {
      this.getTextsByPage('Proceeding');
      this.getSpecialIssues();
    }
  };
</script>
