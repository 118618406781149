<template>
    <transition name="fadepage" mode="out-in">
        <router-view />
    </transition>
</template>

<script>
    export default {
        name: 'ParticipantsMain'
    }
</script>