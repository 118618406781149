import router from '@/router';

export default {
    state: {
        texts: [],
        text: {},
        textsByPage: []
    },
    mutations: {
        setTexts(state, texts) {
            state.texts = texts;
        },
        setTextsByPage(state, texts) {
            state.textsByPage = texts;
        },
        addText(state, text) {
            state.texts = [...state.texts, text];
        },
        setText(state, text) {
            state.text = text;
        }
    },
    actions: {
        getTexts({ commit }) {
            commit('setLoading', true, { root: true });
            this._vm.$axios.get('texts')
                .then(({ data }) => {
                    commit('setTexts', data.texts);
                    commit('setLoading', false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addText({ commit }, {text}) {
            this._vm.$axios.post('texts', text)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addText', data.text);
                    router.push('/admin/texts');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getText({ commit }, text_id) {
            this._vm.$axios.get(`texts/${text_id}`)
                .then(({ data }) => {
                    commit('setText', data.text);
                })
                .catch(err => console.log(err));
        },
        editText({ commit }, text) {
            this._vm.$axios.put(`texts/${text._id}`, text)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/texts');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteText({ commit, getters }, text) {
            this._vm.$axios.delete(`texts/${text._id}`)
                .then(({ data }) => {
                    const texts = getters.texts.filter(item => item._id != text._id);
                    commit('setTexts', texts);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        },
        getTextsByPage({ commit, getters }, page) {
            this._vm.$axios.get(`texts?page=${page}`)
                .then(({ data }) => {
                    commit('setTextsByPage', data.texts);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        texts(state) {
            return state.texts;
        },
        textsByPage(state) {
            return state.textsByPage;
        },
        text(state) {
            return state.text;
        }
    }
}