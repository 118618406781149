import router from '@/router';

export default {
    state: {
        committees: [{}],
        committee: {},
    },
    mutations: {
        setCommittees(state, committees) {
            state.committees = committees;
        },
        addCommittee(state, committee) {
            state.committees = [...state.committees, committee];
        },
        setCommittee(state, committee) {
            state.committee = committee;
        }
    },
    actions: {
        getCommittees({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('committees')
                .then(({ data }) => {
                    commit('setCommittees', data.committees);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addCommittee({ commit }, committee) {
            this._vm.$axios.post('committees', committee)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addCommittee', data.committee);
                    router.push('/admin/committees');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getCommittee({ commit }, committee_id) {
            this._vm.$axios.get(`committees/${committee_id}`)
                .then(({ data }) => {
                    commit('setCommittee', data.committee);
                })
                .catch(err => console.log(err));
        },
        editCommittee({ commit }, committee) {
            this._vm.$axios.put(`committees/${committee._id}`, committee)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/committees');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteCommittee({ commit, getters }, committee) {
            this._vm.$axios.delete(`committees/${committee._id}`)
                .then(({ data }) => {
                    const committees = getters.committees.filter(item => item._id != committee._id);
                    commit('setCommittees', committees);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        committees(state) {
            return state.committees;
        },
        committee(state) {
            return state.committee;
        }
    }
}