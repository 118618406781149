<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/Speakers">Speakers</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Edit Speaker</span>
        </div>
        <h2 class="add-title">Edit Speaker</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="form-control">
                    <VueDropzone id="dropzone" :ref="`myDropzone-${speaker._id}`" :options="options" @vdropzone-complete="afterComplete"></VueDropzone>
                    <div class="multiple-container">
                        <div class="input-container">
                            <label class="bold" for="title">Full name</label>
                            <input type="text" required placeholder="Enter full name" name="title" id="title" v-model="speaker.title">
                        </div>
                        <div class="input-container">
                            <label class="bold" for="university">University</label>
                            <input type="text" required placeholder="Enter full university" name="university" id="university" v-model="speaker.university">
                        </div>
                    </div>
                </div>
                <button type="submit" class="primary-back-btn">Save Speaker</button>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Dropzone from '@/components/back/Dropzone.vue';

    export default {
        name: 'EditSpeaker',
        data() {
            return {
                options: {
                    url: 'https://httpbin.org/post',
                    maxFilesize: 10,
                    headers: { 'My-Awesome-Header': 'header value' },
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Upload photo',
                },
            }
        },
        mounted() {
            this.getSpeaker(this.$route.params.id);
            setTimeout(() => {
                const file = { size: 555, name: this.speaker.title };
                const url = this.speaker.photo;
                this.$nextTick(() => {
                    this.$refs[`myDropzone-${this.speaker._id}`].manuallyAddFile(file, url);
                });
            }, 1000);
        },
        computed: {
            ...mapGetters(['speaker'])
        },
        methods: {
            ...mapActions(['getSpeaker', 'editSpeaker']),
            gotSpeaker() {
                const file = { size: 555, name: this.speaker.title };
                const url = this.speaker.photo;
                this.$nextTick(() => {
                    this.$refs[`myDropzone-${this.speaker._id}`].manuallyAddFile(file, url);
                });
            },
            afterComplete(file) {
                this.speaker.photo = file;
            },
            submitForm() {
                const speakerData = new FormData();
                Object.keys(this.speaker).forEach(item => {
                    speakerData.append("" + item, this.speaker[item]);
                });
                this.editSpeaker(speakerData);
            },
        },
        components: {
            Dropzone
        }
    }
</script>