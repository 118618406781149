import router from '@/router';

export default {
    state: {
        contacts: [],
        contact: {}
    },
    mutations: {
        setContacts(state, contacts) {
            state.contacts = contacts;
        },
        addContact(state, contact) {
            state.contacts = [...state.contacts, contact];
        },
        setContact(state, contact) {
            state.contact = contact;
        }
    },
    actions: {
        getContacts({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('contacts')
                .then(({ data }) => {
                    commit('setContacts', data.contacts);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addContact({ commit }, contact) {
            this._vm.$axios.post('contacts', contact)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addContact', data.contact);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getContact({ commit }, contact_id) {
            this._vm.$axios.get(`contacts/${contact_id}`)
                .then(({ data }) => {
                    commit('setContact', data.contact);
                })
                .catch(err => console.log(err));
        },
        editContact({ commit }, contact) {
            this._vm.$axios.put(`contacts/${contact._id}`, contact)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/contacts');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteContact({ commit, getters }, contact) {
            this._vm.$axios.delete(`contacts/${contact._id}`)
                .then(({ data }) => {
                    const contacts = getters.contacts.filter(item => item._id != contact._id);
                    commit('setContacts', contacts);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        contacts(state) {
            return state.contacts;
        },
        contact(state) {
            return state.contact;
        }
    }
}