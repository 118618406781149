<template>
  <div class="participants">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteParticipant" />
    <div class="flex align-center justify-left participants-head">
      <h3>Participants</h3>
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Name</th>
          <th class="text-left">University</th>
          <th class="text-left">Abstract Title</th>
          <th class="text-left">Status</th>
          <th class="text-center">Situation</th>
        </tr>
        <tr v-if="!participants.length && !loading"><td class="text-left semibold pl-3" colspan="4">No participants were found.</td></tr>
        <tr v-for="(participant, index) in participants" :key="participant._id">
          <td class="text-center">{{ index + 1}}</td>
          <td class="text-left semibold">{{ participant.name + " " + participant.surname }}</td>
          <td class="text-left">{{ participant.university }}</td>
          <td class="text-left">{{ participant.abstract_title }}</td>
          <td class="text-left">{{ participant.status ? 'Approved' : 'Under review' }}</td>
          <td class="text-center">
            <router-link :to="`/admin/participants/${participant._id}`"
              ><img src="@/assets/icon-eye.svg" alt=""/></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="participantDeletion(participant)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!participants.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .participants {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 350% !important;
      }
      @include for-phone-only {
        width: 200%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';

  export default {
    name: 'Participants',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getParticipants();
    },
    computed: {
      ...mapGetters(['participants','loading'])
    },
    methods: {
      ...mapActions(['getParticipants', 'deleteParticipant']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      participantDeletion(participant) {
        this.deleteShownModal = true;
        this.deletion_id = participant;
      },
      onDeleteParticipant() {
        this.deleteParticipant(this.deletion_id);
        this.deleteShownModal = false;
      }
    },
    components: {
      DeleteModal,
    },
  };
</script>
