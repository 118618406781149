<template>
  <div class="flex back-page">
    <SideBar :isOpen="isSideBarOpen" />
    <div class="flex-full back-page-right-side">
      <div class="dashboard-head full-width flex align-center justify-between">
        <div
          class="hamburger-icon"
          @click.stop="isSideBarOpen = !isSideBarOpen"
          @mousedown="isIconPressed = true"
          @mouseleave="isIconPressed = false"
          :class="{ isPressed: isIconPressed }"
        >
          <span></span><span></span><span></span>
        </div>
        <Profile />
      </div>
      <div class="main-back-page p-3">
        <transition name="fadepage" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .back-page {
    overflow: hidden;
    &-right-side {
      height: 100vh;
      overflow: hidden;
    }
  }
  .dashboard-head {
    height: 64px;
    padding: 20px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    box-shadow: 0px 2px 10px rgba(133, 157, 177, 0.1);
    .hamburger-icon {
      width: 30px;
      height: 20px;
      outline: none;
      border: none;
      background-color: transparent;
      position: relative;
      z-index: 6;

      &.isPressed {
        &::before {
          content: "";
          position: absolute;
          width: 50px;
          height: 50px;
          background: #eee;
          border-radius: 50%;
          top: -25px;
          left: -25px;
          transform: translate(30%, 20%);
        }
      }

      span {
        display: inline-block;
        width: 100%;
        height: 2.5px;
        background-color: $secondary;
        position: absolute;
        transition: all 0.6s ease;

        &:first-child {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: 8px;
          left: 0;
        }

        &:nth-child(3) {
          top: 16px;
          left: 0;
        }
      }
    }
  }
  .main-back-page {
    height: calc(100% - 64px);
    position: relative;
    width: 100%;
    font-family: $font3;
    overflow: auto;
    background: #F3F7F9;

    @include for-phone-only{
      padding: 15px !important;
    }
  }
</style>

<script>
  import SideBar from '@/components/back/SideBar.vue';
  import Profile from '@/components/back/Profile.vue';

  export default {
    name: 'BaseDashoard',
    data() {
      return {
        isSideBarOpen: true,
        isIconPressed: false,
      };
    },
    mounted() {
      if(window.localStorage.nprojectToken) {
        const token = JSON.parse(window.localStorage.getItem('nprojectToken'));
        if(token) {
          this.$root.$axios.defaults.headers.common['auth-token'] = token;
        } else {
          this.$root.$axios.defaults.headers.common['auth-token'] = '';
        }
      }
    },
    components: { 
      SideBar,
      Profile
    },
  };
</script>
