<template>
  <div class="slider-photos">
    <button @click="showPrev" class="slider-btn prev-btn">
      <img :src="require('@/assets/icon-arrow.svg')" alt="" />
    </button>
    <VueSlickCarousel v-if="images.length" ref="sliderPhotos" v-bind="SliderSettings">
      <div class="item" v-for="image in images" :key="image">
        <img :src="image" alt="" />
      </div>
    </VueSlickCarousel>
    <button @click="showNext" class="slider-btn next-btn">
      <img :src="require('@/assets/icon-arrow.svg')" alt="" />
    </button>
  </div>
</template>

<style lang="scss">
  .slider-photos {
    position: relative;
    overflow: hidden;
  }
  .slider-btn {
    width: 54px;
    height: 54px;
    position: absolute;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    @include for-phone-only {
      width: 30px;
      height: 30px;
      img {
        width: 50%;
      }
    }
    &.next-btn {
      right: 0;
      img {
        transform: rotate(180deg);
      }
    }

    &.prev-btn {
      left: 0;
    }
  }
  .slick-list {
    .slick-track {
      display: flex;

      div {
        outline: none;
      }

      .slick-active {
        .item {
          img {
            transform: scale(1);
          }
        }
      }
      .slick-slide:last-child {
        img {
          object-position: top;
        }
      }
    }
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 40px;
    width: 100%;
    li {
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 15px rgba(17, 91, 202, 0.5));
      cursor: pointer;

      @include for-phone-only {
        width: 12px;
        height: 12px;
      }

      button {
        opacity: 0;
        visibility: hidden;
      }

      &.slick-active {
        background-color: #fff;
        box-shadow: 0px 2px 15px rgba(17, 91, 202, 0.5);
      }
      &:not(:last-child) {
        margin-right: 35px;

        @include for-phone-only {
          margin-right: 15px;
        }
      }
    }
  }
  .item {
    width: 100%;
    height: 56vh;
    max-height: 540px;
    min-height: 360px;
    overflow: hidden;
    @include for-phone-only {
      height: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
      transform: scale(1.2);
      object-fit: cover;
    }
  }
</style>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Slider',
    data() {
      return {
        SliderSettings: {
          arrows: false,
          dots: true,
          fade: true,
          speed: 900,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
          cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
          touchThreshold: 100,
          draggable: true,
        },
        images: []
      };
    },
    mounted() {
      this.getFeaturedImages();
    },
    computed: {
      ...mapGetters(['featuredImages']),
    },
    methods: {
      ...mapActions(['getFeaturedImages']),
      showPrev() {
        this.$refs.sliderPhotos.prev();
      },
      showNext() {
        this.$refs.sliderPhotos.next();
      },
    },
    components: { 
      VueSlickCarousel 
    },
    watch: {
      featuredImages: function(images) {
        this.images = images.images;
      }
    }
  };
</script>
