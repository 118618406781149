<template>
    <div class="contact main-spacing flex align-center">
        <div class="contact-form flex-full">
            <h2 class="main-title">Contact</h2>
            <form class="mt-5" @submit.prevent="submitForm">
                <Input v-model="contact.name" class="mb-3" type="text" name="name" label="Name" placeholder="Enter name"/>
                <Input v-model="contact.email" class="mb-3" type="email" name="email" label="Email" placeholder="Enter email"/>
                <Input v-model="contact.phone_number" class="mb-3" type="text" name="phone_number" label="Phone number" placeholder="Enter phone number"/>
                <Input v-model="contact.message" class="mb-3" type="textarea" name="message" label="Message" placeholder="Message for us"/>
                <button type="submit" class="primary-btn">Send</button>
            </form>
        </div>
        <div class="contact-illustration">
            <img src="@/assets/illustration-contact.svg">
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .contact-form {
        padding-right: 13rem;
    }
    .contact-illustration{
          @include for-tablet-landscape{
              width: 45%;
          }
          @include  for-tablet-landscape{
              width: 35%;
          }
           @include for-phone-only{
            max-width: 500px;
            width: 100%;
            margin:auto;
        }
    }
    .contact{
        @include for-phone-only{
            flex-direction: column-reverse;
        }
          .contact-form{
        @include for-phone-only{
           width: 90%;
           padding-right: 0;
           margin-top: 20px;
        }
    }
    }
   

</style>

<script>
    import { mapActions } from 'vuex';
    import Input from '@/components/shared/Input.vue';

    export default {
        name: 'Contact',
        data() {
            return {
                contact: {
                    name: '',
                    email: '',
                    phone_number: '',
                    message: ''
                }
            }
        },
        components: {
            Input
        },
        methods: {
            ...mapActions(['addContact']),
            submitForm() {
                this.addContact(this.contact);
                this.contact = {
                    name: '',
                    email: '',
                    phone_number: '',
                    messag: ''
                }
            }
        }
    }
</script>