import router from '@/router';

export default {
    state: {
        sponsors: [],
        sponsor: {}
    },
    mutations: {
        setSponsors(state, sponsors) {
            state.sponsors = sponsors;
        },
        addFile(state, sponsor) {
            state.sponsors = [...state.sponsors, sponsor];
        },
        setSponsor(state, sponsor) {
            state.sponsor = sponsor;
        }
    },
    actions: {
        getSponsors({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('sponsors')
                .then(({ data }) => {
                    commit('setSponsors', data.sponsors);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addSponsor({ commit }, sponsor) {
            this._vm.$axios.post('sponsors', sponsor)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addSponsor', data.sponsor);
                    router.push('/admin/sponsors');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getSponsor({ commit }, sponsor_id) {
            this._vm.$axios.get(`sponsors/${sponsor_id}`)
                .then(({ data }) => {
                    commit('setSponsor', data.sponsor);
                })
                .catch(err => console.log(err));
        },
        editSponsor({ commit }, sponsor) {
            this._vm.$axios.put(`sponsors/${sponsor.get('_id')}`, sponsor)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/sponsors');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteSponsor({ commit, getters }, sponsor) {
            this._vm.$axios.delete(`sponsors/${sponsor._id}`)
                .then(({ data }) => {
                    const sponsors = getters.sponsors.filter(item => item._id != sponsor._id);
                    commit('setSponsors', sponsors);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        sponsors(state) {
            return state.sponsors;
        },
        sponsor(state) {
            return state.sponsor;
        }
    }
}