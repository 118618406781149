import router from '@/router';

export default {
    state: {
        speakers: [],
        speaker: {}
    },
    mutations: {
        setSpeakers(state, speakers) {
            state.speakers = speakers;
        },
        addSpeaker(state, speaker) {
            state.speakers = [...state.speakers, speaker];
        },
        setSpeaker(state, speaker) {
            state.speaker = speaker;
        }
    },
    actions: {
        getSpeakers({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('speakers')
                .then(({ data }) => {
                    commit('setSpeakers', data.speakers);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addSpeaker({ commit }, speaker) {
            this._vm.$axios.post('speakers', speaker)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addSpeaker', data.speaker);
                    router.push('/admin/speakers');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getSpeaker({ commit }, speaker_id) {
            this._vm.$axios.get(`speakers/${speaker_id}`)
                .then(({ data }) => {
                    commit('setSpeaker', data.speaker);
                })
                .catch(err => console.log(err));
        },
        editSpeaker({ commit }, speaker) {
            this._vm.$axios.put(`speakers/${speaker.get('_id')}`, speaker)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/speakers');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteSpeaker({ commit, getters }, speaker) {
            this._vm.$axios.delete(`speakers/${speaker._id}`)
                .then(({ data }) => {
                    const speakers = getters.speakers.filter(item => item._id != speaker._id);
                    commit('setSpeakers', speakers);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        speakers(state) {
            return state.speakers;
        },
        speaker(state) {
            return state.speaker;
        }
    }
}