<template>
  <div class="venue-details">
    <div class="flex align-center justify-between venue-details-head">
      <h3 class="secondary-title bold">Venue details</h3>
      <button
        v-if="!editTypeDataShown"
        @click="toggleTypeData"
        class="primary-back-btn flex align-center venue-images-edit"
      >
        <img
          v-if="editTypeDataShown"
          :src="require('@/assets/icon-edit-white.svg')"
          alt=""
          class="mr-1"
        />Edit Venue
      </button>
      <button
        v-if="editTypeDataShown"
        @click="saveChanges"
        class="primary-back-btn flex align-center venue-images-edit"
      >
        <img :src="require('@/assets/icon-save.svg')" alt="" class="mr-1" />Save
        changes
      </button>
    </div>
    <div class="mt-4">
      <h4 class="secondary-title">Venue Title</h4>
      <p class="normal-p mb-4 mt-2" v-if="!editTypeDataShown">{{ venue.title }}</p>
      <div class="input-container mb-4 mt-2" v-if="editTypeDataShown">
        <input type="text" v-model="venue.title">
      </div>
      <div class="accommodation-type flex-full types-container">
        <h4 class="secondary-title">Accommodation type</h4>
        <ul class="main-list mt-2">
          <li
            v-for="(type, index) in accommodationType"
            :key="index"
            class="main-list-item flex align-center"
          >
            {{ type }}
            <img
              v-if="editTypeDataShown"
              :src="require('@/assets/icon-delete.svg')"
              alt=""
              class="pl-2"
              @click="deleteFromAccommodation(index)"
            />
          </li>
        </ul>
        <div class="flex mt-3 venue-inputs" v-if="editTypeDataShown">
          <div class="input-container">
            <input
              type="text"
              placeholder="Enter Accommodation Type"
              name="Accommodation-type"
              id="Accommodation-type"
              v-model="inputsValue.accommodationTypeValue"
            />
          </div>
          <button @click="addToAccommodationList">
            <img :src="require('@/assets/icon-save.svg')" alt="" />
          </button>
        </div>
      </div>
      <div class="room-type flex-full types-container mt-6">
        <h4 class="secondary-title">Room Type</h4>
        <ul class="main-list mt-2">
          <li
            v-for="(type, index) in roomType"
            :key="index"
            class="main-list-item flex align-center"
          >
            {{ type }}
            <img
              v-if="editTypeDataShown"
              :src="require('@/assets/icon-delete.svg')"
              alt=""
              class="pl-2"
              @click="deleteFromRoom(index)"
            />
          </li>
        </ul>
        <div class="flex mt-3 venue-inputs" v-if="editTypeDataShown">
          <div class="input-container">
            <input
              type="text"
              placeholder="Enter Room Type"
              name="Room-type"
              id="Room-type"
              v-model="inputsValue.roomTypeValue"
            />
          </div>
          <button @click="addToRoomList">
            <img :src="require('@/assets/icon-save.svg')" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.secondary-title {
  font-size: 18px;
}
.input-container {
  max-width: 250px !important;
  width: 100%;
}
.venue-details {
  &-head {
    button {
      min-height: 36px;
    }
  }
  .types-container {
    h4 {
      // display: inline-block;
      font-size: 18px;
    }
    ul {
      display: inline-block;
      img {
        cursor: pointer;
      }
    }
    .venue-inputs {
      button {
        background-color: #11a1fd;
        padding: 5px 10px;
        max-height: 37px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>

<script>
import Input from "@/components/shared/Input.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VenueDetalis",
  data() {
    return {
      editTypeDataShown: false,
      accommodationType: [],
      roomType: [],
      inputsValue: {
        accommodationTypeValue: "",
        roomTypeValue: "",
      },
    };
  },
  mounted() {
    this.getVenue();
  },
  computed: {
    ...mapGetters(["venue"]),
  },
  watch: {
    venue: function(newVenue, oldVenue) {
      this.accommodationType = newVenue.accomodation_types;
      this.roomType = [...newVenue.room_types];
    },
  },
  methods: {
    ...mapActions(["getVenue", "editVenue"]),
    toggleTypeData() {
      this.editTypeDataShown = !this.editTypeDataShown;
    },
    addToAccommodationList() {
      this.accommodationType = [
        ...this.accommodationType,
        this.inputsValue.accommodationTypeValue,
      ];
      this.inputsValue.accommodationTypeValue = "";
    },
    addToRoomList() {
      this.roomType = [...this.roomType, this.inputsValue.roomTypeValue];
      this.inputsValue.roomTypeValue = "";
    },
    deleteFromAccommodation(index) {
      this.accommodationType.splice(index, 1);
    },
    deleteFromRoom(index) {
      this.roomType.splice(index, 1);
    },

    saveChanges() {
      this.editVenue({
        id: this.venue._id,
        venue: {
          title: this.venue.title,
          accomodation_types: this.accommodationType,
          room_types: this.roomType,
        },
      });
      this.toggleTypeData();
    },
  },
  components: {
    Input,
  },
};
</script>
