<template>
  <div class="featured-images">
    <div class="flex align-center justify-between">
      <h3 class="secondary-title bold">Featured Images</h3>
      <button
        v-if="!editPhotosShown"
        @click="toggleEditPhotos"
        class="primary-back-btn flex align-center featured-images-edit"
      >
        <img
          :src="require('../../assets/icon-edit-white.svg')"
          alt=""
          class="mr-1"
        />Edit photos
      </button>
      <button
        v-if="editPhotosShown"
        @click="saveChanges"
        class="primary-back-btn flex align-center featured-images-edit"
      >
        <img
          :src="require('../../assets/icon-save.svg')"
          alt=""
          class="mr-1"
        />Save changes
      </button>
    </div>
    <input
      type="file"
      @change="selectFile"
      class="hidden addPotoInput"
      ref="imageFile"
    />
    <div v-if="loading" class="loader"><Loading /></div>
    <div class="featured-images-grid mt-4">
      <div v-if="editPhotosShown" class="add-photo" @click="onAddImage">
        <img :src="require('../../assets/icon-plus-white.svg')" alt="" />
      </div>
      <div v-for="(image, index) in tempImages" :key="`tempImages` + index">
        <img :src="image.image" alt="" />
        <div v-if="editPhotosShown" class="delete-img" @click="deleteTempImage(index)">
          <img :src="require('../../assets/icon-delete-white.svg')" alt="" />
        </div>
      </div>
      <div v-for="(image,index) in images" :key="`images` + index">
        <img :src="image" alt="" />
        <div v-if="editPhotosShown" class="delete-img" @click="deleteImage(index)">
          <img :src="require('../../assets/icon-delete-white.svg')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .featured-images {
    h3{
      @include for-phone-only{
        font-size: 18px;
        margin-right: 20px;
      }
    }
    &-edit {
      img {
        height: 20px;
      }
    }
    .addPotoInput {
      position: absolute;
    }
    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      grid-gap: 26px;
      > div {
        height: 280px;
        position: relative;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .delete-img {
          width: 32px;
          height: 32px;
          position: absolute;
          top: 16px;
          right: 16px;
          background-color: #ea4335;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      .add-photo {
        background-color: #7d9eb5;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        > img {
          width: 80%;
          height: 80%;
        }
      }
    }
  }
.loader{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: "FeaturedImages",
    data() {
      return {
        editPhotosShown: false,
        images: [],
        tempImages: [],
        deletedImages: [],
      };
    },
    mounted() {
      this.getFeaturedImages();
    },
    computed: {
      ...mapGetters(['featuredImages',"loading"])
    },
    watch:{
      featuredImages : function(newFeaturedImages,oldFeaturesImages){
        this.images = newFeaturedImages.images;
      }
    },
    methods: {
      ...mapActions(['getFeaturedImages', 'deleteFeaturedImage', 'addFeaturedImage']),
      onAddImage() {
        const file = this.$refs.imageFile;
        file.click();
      },
      toggleEditPhotos() {
        this.editPhotosShown = !this.editPhotosShown;
      },
      deleteImage(index) {
        this.images.splice(index, 1);
        this.deletedImages.push(this.featuredImages.images_ids[index]);
        this.featuredImages.images_ids.splice(index, 1);
      },
      deleteTempImage(index) {
        this.tempImages.splice(index, 1);
      },
      selectFile() {
        const fileReader = new FileReader();
        for (let i = 0; i < this.$refs.imageFile.files.length; i++) {
          fileReader.readAsDataURL(this.$refs.imageFile.files[i]);
          const that = this;
          fileReader.addEventListener("load", function(e) {
            that.tempImages = [
              ...that.tempImages,
              {
                image: e.target.result,
              },
            ];
          });
        }
      },
      saveChanges() {
        if (this.tempImages.length) {
          const newTemp = this.tempImages.map(({ image }) => {
            const i = image.indexOf("base64,");
            const buffer = Buffer.from(image.slice(i + 7), "base64");
            return new File([buffer], "NAME", { type: "image/png" });
          });

          const FeaturedImagesData = new FormData();
          newTemp.forEach((temp) => {
            FeaturedImagesData.append("images", temp);
          });
          this.addFeaturedImage({ id: this.featuredImages._id, FeaturedImagesData });
        }

        if (this.deletedImages.length) {
          this.deleteFeaturedImage({
            id: this.featuredImages._id,
            images_ids: this.deletedImages,
          });
        }
        this.toggleEditPhotos();
        this.tempImages = [];
      },
    },
  };
</script>
