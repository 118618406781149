<template>
  <div class="conference main-spacing">
    <div class="tabs">
      <router-link
        ref="speakers"
        :class="{ 'add-rounded': $route.path.includes('important-dates') }"
        to="/conference/speakers"
        >Speakers</router-link
      >
      <router-link
        ref="importantDates"
        :class="{ 'add-rounded': $route.path.includes('committees') }"
        to="/conference/important-dates"
        >Important Dates</router-link
      >
      <router-link ref="committees" to="/conference/committees"
        >Committees</router-link
      >
      <!-- TODO: Refactor -->
      <div class="empty"></div>
    </div>
    <div class="conference-item-content">
      <transition name="fadepage" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .conference {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    .tabs {
      min-height: 7.4rem;
      background: #fff;
      border-radius: 30px 30px 0px 0px;
      display: flex;
      @include for-phone-only {
        flex-direction: column;
      }
      a {
        background: #8bccd5;
        width: 30rem;
        min-height: 7.4rem;
        display: grid;
        place-items: center;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        transition: all 0.3s ease;
        @include for-phone-only {
          font-size: 18px;
          width: 100%;
          border-radius: 0 !important;
        }
        &.router-link-active {
          color: $secondary;
          background: #fff;
        }
        &:first-child {
          border-radius: 30px 0px 0px 0px;
          @include for-phone-only {
            border-radius: 30px 30px 0 0 !important;
          }
        }
        &:first-child.router-link-active + a,
        &:nth-child(2).router-link-active + a {
          background: #8bccd5;
          border-radius: 0px 0px 0px 30px;
        }
        &:first-child.add-rounded {
          border-radius: 30px 0px 30px 0px;
        }
        &:nth-child(2).add-rounded {
          border-radius: 0px 0px 30px 0px;
        }
      }
      .empty {
        flex: 1;
        background: #8bccd5;
        min-height: 7.4rem;
        border-radius: 0px 30px 0px 0px;
        @include for-phone-only {
          display: none;
        }
      }
    }
    &-item-content {
      padding: 8.5rem 4.7rem;
      @include for-phone-only {
        padding: 50px 30px;
      }
    }
  }
</style>

<script>
  export default {
    name: 'Conference',
  };
</script>
