<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/sponsors">Sponsors</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Add Sponsor</span>
        </div>
        <h2 class="add-title">Add Sponsor</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="form-control">
                    <Dropzone :options="options"
                    @fileAdded="onSponsorAdded"></Dropzone>
                    <div>
                        <div class="input-container">
                            <label class="bold" for="title">Title</label>
                            <input type="text" required placeholder="Enter title" name="title" id="title"
                            v-model="sponsor.title">
                        </div>
                        <div class="input-container mt-2">
                            <label class="bold" for="link">Link</label>
                            <input type="text" required placeholder="Enter link" name="link" id="link"
                            v-model="sponsor.link">
                        </div>
                    </div>
                </div>
                <button type="submit" class="primary-back-btn">Add Sponsor</button>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import Dropzone from '@/components/back/Dropzone.vue';
    export default {
        
        name: 'AddSponsor',
        data() {
            return {
                options: {
                    url: 'https://httpbin.org/post',
                    maxFilesize: 10,
                    headers: { 'My-Awesome-Header': 'header value' },
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Upload photo',
                },
                sponsor: {
                    title: '',
                    photo: '',
                    link: ''
                }
            }
        },
          methods: {
            ...mapActions(['addSponsor']),
            submitForm() {
                const sponsorData = new FormData();
                Object.keys(this.sponsor).forEach(item => {
                    sponsorData.append("" + item, this.sponsor[item]);
                });
                this.addSponsor(sponsorData);
                this.sponsor = {
                    title: '',
                    photo: '',
                    link: ''
                }
            },
            onSponsorAdded(photo) {
                this.sponsor.photo = photo;
            }
        },
        components: {
            Dropzone
        }
    }
</script>