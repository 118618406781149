<template>
    <div class="forgotPassword">
        <h1 class="bold">Reset password</h1>
        <p>You can reset your password here</p>
        <div class="input-container">
            <label class="bold" for="">EMAIL</label>
            <input type="text" placeholder="Enter Email">
        </div>
        <button>Resset password</button>
    </div>
</template>

<style lang="scss" scoped>
    .forgotPassword {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: white;
        width: 450px;
        text-align: center;
        padding: 40px;
        border-radius: 3px;
    }
    h1 {
        font-size: 30px;
    }
    p {
        font-size: 14px;
        color: #8DAABE;
        margin: 20px 0 50px 0;
    }
    .input-container {
        text-align: left;
        margin-bottom: 30px;
        input {
            width: 100%;
            border: 1px solid #7E9FB6;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 8px 15px;
            font-size: 14px;
            color: #8BA9BD;
            outline: none;
        }
        label {
            display: inline-block;
            color: #7D9EB5;
            font-size: 12px;
            margin-bottom: 7px;
        }
    }
    button {
        width: 100%;
        background: #11A1FD;
        color: #fff;
        padding: 8px 0;
        font-size: 14px;
        line-height: 24px;
        margin-top: 15px;
    }
</style>
<script>
    export default {
        name: 'ForgotPassword'
    };
</script>
