import router from '@/router';

export default {
    state: {
        specialIssues: [],
        specialIssue: {}
    },
    mutations: {
        setSpecialIssues(state, specialIssues) {
            state.specialIssues = specialIssues;
        },
        addSpecialIssue(state, specialIssue) {
            state.specialIssues = [...state.specialIssues, specialIssue];
        },
        setSpecialIssue(state, specialIssue) {
            state.specialIssue = specialIssue;
        }
    },
    actions: {
        getSpecialIssues({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('special-issues')
                .then(({ data }) => {
                    commit('setSpecialIssues', data.specialIssues);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addSpecialIssue({ commit }, specialIssue) {
            this._vm.$axios.post('special-issues', specialIssue)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addSpecialIssue', data.specialIssue);
                    router.push('/admin/special-issues');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getSpecialIssue({ commit }, specialIssue_id) {
            this._vm.$axios.get(`special-issues/${specialIssue_id}`)
                .then(({ data }) => {
                    commit('setSpecialIssue', data.specialIssue);
                })
                .catch(err => console.log(err));
        },
        editSpecialIssue({ commit }, specialIssue) {
            this._vm.$axios.put(`special-issues/${specialIssue._id}`, specialIssue)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    router.push('/admin/special-issues');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        deleteSpecialIssue({ commit, getters }, specialIssue) {
            this._vm.$axios.delete(`special-issues/${specialIssue._id}`)
                .then(({ data }) => {
                    const specialIssues = getters.specialIssues.filter(item => item._id != specialIssue._id);
                    commit('setSpecialIssues', specialIssues);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        specialIssues(state) {
            return state.specialIssues;
        },
        specialIssue(state) {
            return state.specialIssue;
        }
    }
}