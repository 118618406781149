<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/committees">Committees</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Add Committee</span>
        </div>
        <h2 class="add-title">Add committees</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="form-control">
                    <div class="input-container">
                        <label class="bold" for="title">Full name</label>
                        <input type="text" placeholder="Enter full name" name="title" id="title" v-model="committee.title">
                    </div>
                    <div class="input-container">
                        <label class="bold" for="country">Country</label>
                        <input type="text" placeholder="Enter country" name="country" id="country" v-model="committee.country">
                    </div>
                </div>
                <div class="form-control">
                    <div class="input-container flex-full">
                        <label class="bold" for="position">Position</label>
                        <input type="text" placeholder="Enter position" name="position" id="position" v-model="committee.position">
                    </div>
                    <InputSelect
                        label="Category"
                        placeholder="Select category"
                        color="white"
                        class="mb-3 flex-full input-container"
                        :options="categoriesTitles"
                        :fromBack="true"
                        @change="committee.category = $event"
                    />
                </div>
                <div class="form-control">
                    <div class="input-container">
                        <label class="bold" for="chair">Chair</label>
                        <input type="checkbox" name="chair" id="chair" v-model="committee.chair">
                    </div>
                </div>
                <button type="submit" class="primary-back-btn">Add Committee</button>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import InputSelect from '@/components/shared/InputSelect.vue';

    export default {
        name: 'AddCommittee',
        data() {
            return {
                committee: {
                    title: '',
                    position: '',
                    country: '',
                    category: '',
                    chair: false
                }
            }
        },
        mounted() {
            this.getCategories();
        },
        methods: {
            ...mapActions(['addCommittee', 'getCategories']),
            submitForm() {
                const category = this.categories.find(category => category.title == this.committee.category);
                this.committee.category = category._id;
                this.addCommittee(this.committee);
            }
        },
        computed: {
            ...mapGetters(['categories']),
            categoriesTitles() {
                return this.categories.map(category => category.title);
            }
        },
        components: {
            InputSelect
        }   
    }
</script>