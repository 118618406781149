<template>
  <div class="add-view add-text">
    <div class="breadcrumb">
      <router-link to="/admin/texts">Texts</router-link>
      <img src="@/assets/icon-back-arrow-right.svg" />
      <span>Add Text</span>
    </div>
    <h2 class="add-title">Add Text</h2>
    <div class="add-form-container">
      <form @submit.prevent="submitForm">
        <div class="input-container">
          <label class="bold" for="title">Title</label>
          <input
            type="text"
            placeholder="Enter title"
            name="title"
            id="title"
            required
            v-model="text.title"
          />
        </div>
        <div class="input-container">
          <label class="bold" for="Content">Content</label>
          <RichText label="Enter content in here" name="content" v-model="text.content"/>
        </div>
        <InputSelect
          label="Page"
          placeholder="Select page"
          color="white"
          class="mb-3"
          :options="['Home', 'Venue', 'Register', 'Proceeding']"
          :fromBack="true"
          @change="text.page = $event"
        />
        <button type="submit" class="primary-back-btn">
          Add Text
        </button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .add-form-container {
    max-width: 75rem;
    width: 100%;
  }
  .input-container {
    margin-bottom: 30px;
    // max-width: 70rem !important;
    width: 100%;
    input {
      // max-width: 70rem !important;
      width: 100%;
    }
  }
</style>

<script>
  import RichText from "@/components/back/RichText.vue";
  import InputSelect from "@/components/shared/InputSelect.vue";
  import { mapActions } from 'vuex';

  export default {
    name: 'AddText',
    data() {
      return {
        text: {
          title: '',
          content: '',
          page: '',
        },
      };
    },
    components: {
      RichText,
      InputSelect,
    },
    methods:{
      ...mapActions(['addText']),
      submitForm() {
        this.addText({
            text: this.text
        });
      }
    }
  };
</script>
