<template>
   <div class="participant">
        <div class="flex align-center justify-between">
            <h3>Participant info: {{ participant.name + " " + participant.surname }}</h3>
            <router-link to="/admin/participants" class="primary-back-btn">Go back</router-link>
        </div>
        <div>
            <h4>Name</h4>
            <p>{{ participant.name }}</p>
            <h4>Surname</h4>
            <p>{{ participant.surname }}</p>
            <h4>Email</h4>
            <a :href="`mailto:${participant.email}`">{{ participant.email }}</a>
            <h4>Phone</h4>
            <a :href="`tel:${participant.phone_number}`">{{ participant.phone_number }}</a>
            <h4>University</h4>
            <p>{{ participant.university }}</p>
            <h4>Department</h4>
            <p>{{ participant.department }}</p>
            <h4>Topic</h4>
            <p>{{ participant.topic }}</p>
            <h4>Presentation Type</h4>
            <p>{{ participant.presentation_type }}</p>
            <h4 class="bold">Abstract Title</h4>
            <p>{{ participant.abstract_title }}</p>
            <h4>Accomodation Type</h4>
            <p>{{ participant.accomodation_type }}</p>
            <h4>Room Type</h4>
            <p>{{ participant.room_type }}</p>
            <h4>Hotel Check In Date</h4>
            <p>{{ checkinDate }}</p>
            <h4>Hotel Check Out Date</h4>
            <p>{{ checkoutDate }}</p>
            <h4>Abstract File</h4>
            <p><a :href="participant.abstract_file" download target="_blank">{{ participant.abstract_title }}</a></p>
            <div class="flex mt-5" v-if="!participant.status">
                <button class="bold btn btn--approve mr-2 capitalize" @click="onChangeStatus">Approve</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .participant {
        > div:first-child {
            margin-bottom: 25px;
            h3 {
                font-size: 24px;
            }
        }
        > div:nth-child(2) {
            background: #fff;
            padding: 32px;
            h4 {
                font-size: 18px;
                color: #223345;
                margin-bottom: 15px;
                text-transform: capitalize;
            }
            p {
                font-size: 14px;
                color: #223345;
                width: 50%;
                margin-bottom: 20px;
            }
            a {
                color: #11A1FD;
                font-size: 14px;
                margin-bottom: 20px;
                display: inline-block;
            }
        }
        .btn {
            color: #fff;
            padding: 10px 25px;
            transition: all .3s ease;
            &--approve {
                background: rgb(90, 160, 90);
                &:hover {
                    background: rgba(90, 160, 90, .7);
                }
            }
            &--remove {
                background: red;
                &:hover {
                    background: rgba(255, 0, 0, .7);
                }
            }
        }
    }
</style>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Participant',
        mounted() {
            this.getParticipant(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['participant']),
            checkinDate() {
                return new Date(this.participant.checkin_date).toDateString();
            },
            checkoutDate() {
                return new Date(this.participant.checkout_date).toDateString();
            }
        },
        methods: {
            ...mapActions(['getParticipant', 'changeStatus']),
            onChangeStatus() {
                this.changeStatus(this.participant._id);
                this.$router.push('/admin/participants');
            }
        }
    }
</script>