<template>
    <div class="table-container">
        <table class="full-width">
        <tr>
            <th class="secondary-color semibold p-1">Order</th>
            <th class="secondary-color semibold text-left p-1">Name</th>
            <th class="secondary-color semibold text-left p-1">University</th>
            <th class="secondary-color semibold text-left p-1">Type</th>
            <th class="secondary-color semibold text-left p-1">Abstract Title</th>
            <th class="secondary-color semibold text-left p-1">Situation</th>
        </tr>
        <tr v-if="!data.length">
            <td colspan="6" class="secondary-color p-1">No participants are registered yet!</td>
        </tr>
        <tr v-for="(participant, index) in data" :key="participant._id">
            <td class="secondary-color flex-center p-1">{{ index + 1 }}</td>
            <td class="secondary-color p-1">{{ `${participant.title} ${participant.name} ${participant.surname}` }}</td>
            <td class="secondary-color p-1">{{ participant.university }}</td>
            <td class="secondary-color p-1">{{ participant.presentation_type }}</td>
            <td class="secondary-color p-1">{{ participant.abstract_title }}</td>
            <td class="secondary-color p-1">{{ participant.status ? 'Accept' : 'Under review' }}</td>
        </tr>
    </table>
    </div>
</template>

<style lang="scss" scoped>
    .table-container{
        width: 100%;
        overflow: auto;
    }
    table {
        border-collapse: collapse;
        @include for-phone-only {
            width: 1200px;
        }
    }
    th {
        border-bottom: 2px solid $primary;
        font-size: 18px;
        color: $secondary;
    }
    td {
        font-size:16px ;
        line-height: 24px;
        color: $secondary;
    }
</style>

<script>
    export default {
        name: 'Table',
        props: ['data']
    };
</script>