import Vue from "vue";
import Vuex from "vuex";

// Modules
import auth from "./modules/auth.module";
import categories from "./modules/categories.module";
import texts from "./modules/texts.module";
import special_issues from "./modules/special_issues.module";
import committees from "./modules/committees.module";
import important_dates from "./modules/important_dates.module";
import contacts from "./modules/contacts.module";
import files from "./modules/files.module";
import speakers from "./modules/speakers.module";
import featured_images from "./modules/featured_images.module";
import sponsors from "./modules/sponsors.module";
import participants from "./modules/participants.module";
import venue from "./modules/venue.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
  },
  actions: {},
  modules: {
    auth,
    categories,
    special_issues,
    committees,
    important_dates,
    texts,
    contacts,
    files,
    speakers,
    featured_images,
    sponsors,
    participants,
    venue,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
});
