<template>
  <div class="contacts">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteContact"/>
    <div class="flex align-center justify-left contacts-head">
      <h3>Contacts</h3>
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Name</th>
          <th class="text-left">Email</th>
          <th class="text-left">Phone</th>
          <th class="text-left">Message</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!contacts.length && !loading"><td class="text-left semibold pl-3" colspan="4">No contacts were found. Please add some!</td></tr>
        <tr v-for="(contact, index) in contacts" :key="contact._id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left semibold">{{ contact.name }}</td>
          <td class="text-left"><a :href="`mailto:${contact.email}`">{{ contact.email }}</a></td>
          <td class="text-left"><a :href="`tel:${contact.phone_number}`">{{ contact.phone_number }}</a></td>
          <td class="text-left">
            {{ contact.message }}
          </td>
          <td class="text-center">
            <router-link :to="`/admin/contacts/${contact._id}`">
              <img src="@/assets/icon-eye.svg" alt="" />
            </router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="contactDeletion(contact)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!contacts.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .contacts {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 300% !important;
      }
      @include for-phone-only {
        width: 200%;
      }
      tr {
        > td:nth-child(5) {
          width: 400px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
          line-height: 28px;
        }
      }
      img { 
        margin: 0 5px;
        cursor: pointer;
      }
      a {
        color: #11a1fd;
        text-decoration: underline;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';
  
  export default {
    name: 'Contacts',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getContacts();
    },
    computed: {
      ...mapGetters(['contacts','loading'])
    },
    methods: {
      ...mapActions(['getContacts', 'deleteContact']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      contactDeletion(contact) {
        this.deleteShownModal = true;
        this.deletion_id = contact;
      },
      onDeleteContact() {
        this.deleteContact(this.deletion_id);
        this.deleteShownModal = false;
      }
    },
    components: {
      DeleteModal,
    },
  }
</script>
