<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/committees">Committees</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Edit Committee</span>
        </div>
        <h2 class="add-title">Edit committee</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="form-control">
                    <div class="input-container">
                        <label class="bold" for="title">Full name</label>
                        <input v-if="committee.title" type="text" placeholder="Enter full name" name="title" id="title" v-model="committee.title">
                    </div>
                    <div class="input-container">
                        <label class="bold" for="country">Country</label>
                        <input v-if="committee.country" type="text" placeholder="Enter country" name="country" id="country" v-model="committee.country">
                    </div>
                </div>
                <div class="form-control">
                    <div class="input-container flex-full">
                        <label class="bold" for="title">Position</label>
                        <input v-if="committee.position" type="text" placeholder="Enter position" name="title" id="title" v-model="committee.position">
                    </div>
                    <InputSelect
                        label="Category"
                        :placeholder="committeeCategory"
                        color="white"
                        class="mb-3 flex-full input-container"
                        :options="categoriesTitles"
                        :fromBack="true"
                        @change="committee.category = $event"
                    />
                </div>
                <div class="form-control">
                    <div class="input-container">
                        <label class="bold" for="chair">Chair</label>
                        <input type="checkbox" name="chair" id="chair" v-model="committee.chair">
                    </div>
                </div>
                <button type="submit" class="primary-back-btn">Save Committee</button>
            </form>
        </div>
    </div>
</template>

<script>
    import InputSelect from '@/components/shared/InputSelect.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'EditCommittee',
        components: {
            InputSelect
        },
        mounted() {
            this.getCategories();
            this.getCommittee(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['committee', 'categories']),
            committeeCategory() {
                const category = this.categories.find(category => category._id == this.committee.category);
                return category?.title;
            },
            categoriesTitles() {
                return this.categories.map(category => category.title);
            }
        },
        methods: {
            ...mapActions(['getCommittee', 'getCategories', 'editCommittee']),
            submitForm() {
                const category = this.categories.find(category => (category.title == this.committee.category) || (category._id == this.committee.category));
                this.committee.category = category._id;
                this.editCommittee(this.committee);
            }
        }
    }
</script>