<template>
  <div class="rich-text">
    <editor
      api-key="f20rlklg3b5ybqb6tl6xqn89k13xrm62sb2dyiz69uebjszb"
      :initial-value="value"
      @input="updateValue($event)"
      :init="{
        height: 269,
        menubar: false,
        branding: false,
        placeholder: label,
        toolbar_location: 'bottom',
        resize: false,
        skin: 'naked',
        statusbar: false,
        plugins: [
          'advlist autolink lists link preview anchor',
          'code fullscreen',
          'paste code',
        ],
        toolbar:
          'undo redo formatselect bold italic backcolor \
            alignleft aligncenter alignright alignjustify \
            bullist numlist outdent indent',
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
  .rich-text {
    border: 1px solid $primary;
  }
</style>

<script>
  import Editor from '@tinymce/tinymce-vue';
  
  export default {
    name: 'RichText',
    props: ['label', 'value'],
    components: {
      editor: Editor,
    },
    methods: {
      updateValue(value) {
        this.$emit('input', value);
      },
    },
  };
</script>
