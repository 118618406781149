<template>
  <div class="specialIssues">
    <DeleteModal
      v-if="deleteShownModal"
      :click="closeDeleteModal"
      @onDelete="onDeleteSpecialIssue"
    />
    <div class="flex align-center justify-between specialIssues-head">
      <h3>Special Issues</h3>
      <router-link to="/admin/special-issues/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Issue</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Title</th>
          <th class="text-left">Link</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!specialIssues.length && !loading">
          <td class="text-left semibold pl-3" colspan="4">
            No special issues were found. Please add some!
          </td>
        </tr>
        <tr
          v-for="(specialIssue, index) in specialIssues"
          :key="specialIssue._id"
        >
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left semibold">{{ specialIssue.title }}e</td>
          <td class="text-left">
            <a :href="specialIssue.link" target="_blank">Go to page</a>
          </td>
          <td class="text-center">
            <router-link :to="`/admin/special-issues/${specialIssue._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="specialIssueDeletion(specialIssue)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!specialIssues.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .specialIssues {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 150%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
      a {
        color: #11a1fd;
        text-decoration: underline;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';

  export default {
    name: 'SpecialIssues',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: '',
      };
    },
    mounted() {
      this.getSpecialIssues();
    },
    computed: {
      ...mapGetters(['specialIssues','loading']),
    },
    methods: {
      ...mapActions(['getSpecialIssues', 'deleteSpecialIssue']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      specialIssueDeletion(specialIssue) {
        this.deleteShownModal = true;
        this.deletion_id = specialIssue;
      },
      onDeleteSpecialIssue() {
        this.deleteSpecialIssue(this.deletion_id);
        this.deleteShownModal = false;
      },
    },
    components: {
      DeleteModal,
    },
  };
</script>
