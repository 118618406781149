<template>
    <VueDropzone id="dropzone" ref="dropzone" :options="options" @vdropzone-complete="afterComplete"></VueDropzone>
</template>

<style lang="scss">
    .dropzone .dz-preview {
        margin: 0px !important;
    }
    .dropzone .dz-message {
        font-weight: bold;
        color: #7D9EB5;
        font-size: 16px;
        max-width: 135px;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 45% !important;
        transform: translateY(-50%);
    }
    .vue-dropzone {
        width: 21.6rem;
        height: 21.6rem;
        background: #F3F7F9 !important;
        border: 0px !important;
        font-family: $font3 !important;
        &:hover {
            background: #dbdfe0 !important;
        }
    }
</style>

<script>
    export default {
        name: 'Dropzone',
        props: ['options'],
        methods: {
            afterComplete(file) {
                this.$emit('fileAdded', file);
            }
        }
    }
</script>