<template>
  <div class="align-center inline-flex checkbox">
    <input
      :type="type"
      :name="name"
      :id="`${name}-${label}`"
      class="p-absolute no-opacity"
      @change="handleChange"
    />
    <label
      :for="`${name}-${label}`"
      class="p-0 p-relative inline-flex align-center cursor medium uppercase"
      >{{ label }}</label
    >
  </div>
</template>

<style lang="scss" scoped>
  .checkbox:not(:first-of-type) {
    label {
      margin-left: 20px;
    }
  }
  input {
    &:checked + label::before {
      background: $primary;
    }
    &:checked + label::after {
      content: "";
      position: absolute;
      left: 5px;
      top: 11px;
      background: #fff;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff,
        4px -6px 0 #fff, 4px -8px 0 #fff;
      transform: rotate(45deg);
    }
  }
  label {
    color: $secondary;
    &::before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid #0097ab;
      box-sizing: border-box;
      border-radius: 2.5px;
    }
  }
</style>
<script>

export default {
  name: 'Checkox',
  props: ['type', 'label', 'name'],
  methods: {
    handleChange() {
      this.$emit('change');
    },
  },
};
</script>
