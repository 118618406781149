import router from '@/router';

export default {
    state: {
        loggedUser: '' || JSON.parse(window.localStorage.getItem('nprojectLoggedUser')),
        userToken: '' || JSON.parse(window.localStorage.getItem('nprojectToken'))
    },
    mutations: {
        setUserData(state, user) {
            state.loggedUser = user.user;
            state.userToken = user.token;
        }
    },
    actions: {
        login({ commit }, user) {
            this._vm.$axios.post('user/login', user)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    data.message = undefined;
                    commit('setUserData', data);
                    window.localStorage.setItem('nprojectToken', JSON.stringify(data.token));
                    window.localStorage.setItem('nprojectLoggedUser', JSON.stringify(data.user));
                    router.push('/admin');
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        logout({ commit }) {
            commit('setUserData', {
                user: '',
                token: ''
            });
            window.localStorage.removeItem('nprojectToken');
            window.localStorage.removeItem('nprojectLoggedUser');
            router.push('/');
        }
    },
    getters: {
        loggedUser(state) {
            return state.loggedUser;
        }
    }
}