<template>
  <div class="categories">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteCategory"/>
    <div class="flex align-center justify-between categories-head">
      <h3>Categories</h3>
      <router-link to="/admin/categories/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Category</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Title</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!categories.length && !loading"><td class="text-left semibold pl-3" colspan="4">No categories were found. Please add some!</td></tr>
        <tr v-for="(category, index) in categories" :key="category._id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left semibold">{{ category.title }}</td>
          <td class="text-center">
            <router-link :to="`/admin/categories/${category._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="categoryDeletion(category)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!categories.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .categories {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only {
        width: 150%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
      tr {
        th:nth-child(2) {
          padding-left: 300px;
          @include for-phone-only {
            padding-left: 100px;
          }
        }
        td:nth-child(2) {
          padding-left: 300px;
          @include for-phone-only {
            padding-left: 100px;
          }
        }
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';
  
  export default {
    name: 'Categories',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getCategories();
    },
    computed: {
      ...mapGetters(['categories','loading'])
    },
    methods: {
      ...mapActions(['getCategories', 'deleteCategory']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      categoryDeletion(category) {
        this.deleteShownModal = true;
        this.deletion_id = category;
      },
      onDeleteCategory() {
        this.deleteCategory(this.deletion_id);
        this.deleteShownModal = false;
      }
    },
    components: {
      DeleteModal,
    },
  }
</script>
