<template>
  <div class="flex align-start flex-column">
    <label :for="name" class="primary-color bold uppercase">{{ label }}</label>
    <input
      :placeholder="placeholder"
      v-if="type != 'textarea' && type != 'radio'"
      :type="type"
      :name="name"
      :value="value"
      :required="required"
      @input="updateValue($event.target.value)"
      class="full-width no-outline py-1 px-2"
    />
    <textarea
      v-if="type == 'textarea'"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      :required="required"
      @input="updateValue($event.target.value)"
      class="no-resize full-width no-outline py-1 px-2"
    >
    </textarea>
    <div class="flex" v-if="type == 'radio' || type == 'checkbox'">
      <Checkbox
        v-for="option in options"
        :key="option"
        :type="type"
        :label="option"
        :name="name"
        @change="CheckboxChange(option)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  textarea {
    min-height: 17.6rem;
    font-family: $font;
  }
  input,
  textarea {
    border-radius: 3px;
    border: 1px solid $secondary;
    &::placeholder {
      font-size: 14px;
      color: $gray;
      font-weight: 500;
    }
  }
  label {
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 24px;
  }
</style>

<script>
  import Checkbox from "@/components/shared/Checkbox.vue";

  export default {
    name: "Input",
    props: ["label", "type", "name", "placeholder", "options", "value", "required"],
    methods: {
      CheckboxChange(value) {
        this.$emit("checkboxSelected", value);
      },
      updateValue(value) {
        this.$emit('input', value);
      }
    },
    components: {
      Checkbox,
    },
  };
</script>
