import router from "@/router";

export default {
  state: {
    venue: {},
  },
  mutations: {
    setVenue(state, venue) {
      state.venue = venue;
    },
  },
  actions: {
    getVenue({ commit }) {
      commit("setLoading", true, { root: true });
      this._vm.$axios
        .get(`venues/`)
        .then(({ data }) => {
          commit("setVenue", data.venues[0]);
          commit("setLoading", false, { root: true });
        })
        .catch((err) => console.log(err));
    },
    editVenue({ commit }, { id, venue }) {
      this._vm.$axios
        .put(`venues/${id}`, venue)
        .then(({ data }) => {
          if(data.status && data.status == 500) {
            throw new Error(data.message);
          }
        })
        .catch(err => {
          this._vm.$toast.open({
              message: err + "", 
              type: 'error'
          }) 
        });
    },
    addVenueImage({ commit }, { id, VenueImageData }) {
      commit("setLoading", true, { root: true });
      this._vm.$axios
        .post(`venues/${id}/images`, VenueImageData)
        .then(({ data }) => {
          if(data.status && data.status == 500) {
            throw new Error(data.message);
          }
          commit("setVenue", data.venue);
          commit("setLoading", false, { root: true });
          this._vm.$toast.open(data.message);
        })
        .catch(err => {
          this._vm.$toast.open({
              message: err + "", 
              type: 'error'
          }) 
        });
    },
    deleteVenueImage({ commit, getters }, { id, images_ids }) {
      this._vm.$axios
        .post(`venues/${id}/images/delete`, { images_ids })
        .then(({ data }) => {
          this._vm.$toast.open(data.message);
        })
        .catch((err) => console.log(err));
    },
  },
  getters: {
    venue(state) {
      return state.venue;
    },
  },
};
