<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/important-dates">Important Dates</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Add Important Date</span>
        </div>
        <h2 class="add-title">Add important date</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="input-container">
                    <label class="bold" for="title">Title</label>
                    <input type="text" placeholder="Enter title" name="title" id="title" required v-model="importantDate.title">
                </div>
                <div class="input-container">
                    <label class="bold" for="date">Date</label>
                    <input type="text" placeholder="Enter date" name="date" id="date" required v-model="importantDate.date">
                </div>
                <button type="submit" class="primary-back-btn">Add date</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .input-container {
        margin-bottom: 30px;
    }
</style>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'AddImportantDate',
        data() {
            return {
                importantDate: {
                    title: '',
                    date: ''
                }
            }
        },
        methods: {
            ...mapActions(['addImportantDate']),
            submitForm() {
                this.addImportantDate(this.importantDate);
            }
        }
    }
</script>