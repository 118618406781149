<template>
  <div class="text">
    <div class="flex align-center justify-between">
      <h3>{{text.title}}</h3>
      <router-link to="/admin/texts" class="primary-back-btn"
        >Go back</router-link
      >
    </div>
    <div>
      <h4 class="bold">Content</h4>
      <p v-html="text.content">
      </p>
      <h4>Page</h4>
      <a class="bold" target="_blank" :href="getPageLink">{{text.page}}</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .text {
    > div:first-child {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
      }
    }
    > div:nth-child(2) {
      background: #fff;
      padding: 32px;
      h4 {
        font-size: 18px;
        color: #223345;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        color: #223345;
        width: 50%;
        margin-bottom: 20px;
      }
      a {
        color: #11a1fd;
        font-size: 14px;
      }
    }
  }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    mounted() {
      this.getText(this.$route.params.id);
    },
    computed: {
      ...mapGetters(['text']),
      getPageLink() {
        switch(this.text.page) {
          case 'Register': {
            return `/participants/register`;
          }
          case 'Home': {
            return `/`
          }
          default: {
            return `/${this.text?.page?.toLowerCase()}`
          }
        }
      }
    },
    methods: {
      ...mapActions(['getText', 'editText']),
    },
  };
</script>
