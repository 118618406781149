import router from '@/router';

export default {
    state: {
        files: [],
        file: null
    },
    mutations: {
        setFiles(state, files) {
            state.files = files;
        },
        addFile(state, file) {
            state.files = [...state.files, file];
        },
        setFile(state, file) {
            state.file = file;
        }
    },
    actions: {
        getFiles({ commit }) {
            commit("setLoading", true, { root: true });
            this._vm.$axios.get('files')
                .then(({ data }) => {
                    commit('setFiles', data.files);
                    commit("setLoading", false, { root: true });
                })
                .catch(err => console.log(err));
        },
        addFile({ commit }, file) {
            this._vm.$axios.post('files', file)
                .then(({ data }) => {
                    if(data.status && data.status == 500) {
                        throw new Error(data.message);
                    }
                    commit('addFile', data.file);
                    router.push('/admin/files');
                    this._vm.$toast.open(data.message);
                })
                .catch(err => {
                    this._vm.$toast.open({
                        message: err + "", 
                        type: 'error'
                    }) 
                });
        },
        getFile({ commit }, file_id) {
            this._vm.$axios.get(`files/${file_id}`)
                .then(({ data }) => {
                    commit('setFile', data.file);
                })
                .catch(err => console.log(err));
        },
        deleteFile({ commit, getters }, file) {
            this._vm.$axios.delete(`files/${file._id}`)
                .then(({ data }) => {
                    const files = getters.files.filter(item => item._id != file._id);
                    commit('setFiles', files);
                    this._vm.$toast.open(data.message);
                })
                .catch(err => console.log(err));
        }
    },
    getters: {
        files(state) {
            return state.files;
        },
        file(state) {
            return state.file;
        }
    }
}