<template>
  <div class="important-dates flex">
    <div class="date-keys mr-4 flex-full">
      <div v-for="importantDate in importantDates" :key="importantDate._id" class="flex align-center justify-start full-width">
        <h2 class="extrabold secondary-color mb-3 ">
          {{ importantDate.title }}:
        </h2>
        <p class="mb-3 secondary-color">{{ importantDate.date }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .important-dates {
    .date-keys {
      @include for-phone-only {
        margin-right: 0 !important;
      }
      > div {
        @include for-tablet-landscape {
          justify-content: space-between;
        }
        @include for-phone-only {
          flex-direction: column;
          h2 {
            width: 100%;
            margin-bottom: 10px !important;
          }
          p {
            width: 100%;
          //   margin-bottom: 10px !important;
          }
        }
      }
    }
    h2 {
      min-width: 600px;
      @include for-tablet-landscape {
        min-width: unset;
        width: 60%;
      }
    }
    p {
      @include for-tablet-landscape {
        width: 35%;
      }
    }
    h2,
    p {
      font-size: 22px;
      line-height: 27px;

      @include for-phone-only {
        font-size: 18px;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'ImportantDates',
    mounted() {
      this.getImportantDates();
    },
    computed: {
      ...mapGetters(['importantDates'])
    },
    methods: {
      ...mapActions(['getImportantDates'])
    }
  };
</script>
