<template>
  <div class="venue-page main-spacing">
    <ImageModal :show="showModal" :source="imageSource" @closed="closedModal" />
    <div class="text-container grid-2">
      <div class="text" v-for="text in textsByPage" :key="text._id">
        <h2 class="main-title mb-4">{{ text.title }}</h2>
        <div class="text-content " v-html="text.content">
        </div>
      </div>
    </div>
    <div class="grid-gallery-container mt-10">
      <h2 class="main-title">Gallery</h2>
      <div class="grid-gallery mt-6">
        <div v-for="image in venue.images" :key="image" @click.stop="imageClicked(image)">
          <img :src="image" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .venue-page {
    .grid-2 {
      @include for-tablet-landscape {
        grid-template-columns: 1fr;
        grid-gap: 0px;
      }
    }
  }
  .grid-gallery > div:nth-child(9n) {
    grid-column: 3/5;
    grid-row: 2/4;

    @include for-tablet-landscape {
      grid-column: unset;
      grid-row: unset;
    }
  }
  .grid-gallery div {
    cursor: pointer;
  }
  .venue-page {
    .text {
      margin-top: 0 !important;
    }
  }
</style>

<script>
 import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'Venue',
    data() {
      return {
        showModal: false,
        imageSource: '',
      };
    },
    methods: {
      ...mapActions(['getTextsByPage', 'getVenue']),
      imageClicked(source) {
        this.showModal = true;
        this.imageSource = source;
      },
      closedModal() {
        this.showModal = false;
        this.imageSource = '';
      },
    },
    computed: {
      ...mapGetters(['textsByPage', 'venue'])
    },
    mounted() {
      this.getTextsByPage('Venue');
      this.getVenue();
    }
  };
</script>
