<template>
  <div>
    <label
      for=""
      class="primary-color bold uppercase"
      :class="{ 'label-back': fromBack }"
      >{{ label }}
    </label>
    <v-select
      :class="[
        { 'filter-white': color === 'white' },
        { 'select-back': fromBack },
      ]"
      :placeholder="placeholder"
      :options="options"
      :value="selected"
      @input="updateValue"
    ></v-select>
  </div>
</template>

<style lang="scss">
  .select-back {
    font-family: $font3;
    color: #8ba9bd !important;
    .vs__dropdown-toggle {
      border: 1px solid #7e9fb6;
    }
    .vs__actions {
      padding-right: 15px;
      cursor: pointer;
      svg {
        fill: #8ba9bd !important;
      }
    }
    .vs__dropdown-menu {
      & > * {
        &:hover,
        &:focus {
          background-color: #11a1fd !important;
        }
      }
    }
  }
  .filter-white {
    color: $secondary;
    .vs__dropdown-toggle {
      border-radius: 0px;
      padding: 0px;
      border: 1px solid inherit;
    }
    .vs__search,
    .vs__search:focus {
      font-size: 16px;
      color: rgba($secondary, 0.45);
      font-weight: 500;
      padding: 8px 0;
      padding-left: 15px;
      margin: 0px;
    }
    .vs__actions {
      padding-right: 15px;
      cursor: pointer;
      svg {
        fill: $primary;
      }
    }
    .vs__selected {
      font-size: 15px;
      color: inherit;
      padding: 8px 0;
      padding-left: 15px;
      margin: 0;
    }
    .vs__dropdown-menu {
      font-size: 15px;
      padding-left: 0px;
      top: 100%;
      border: 1px solid inherit;
      border-top: none;

      & > * {
        padding: 8px 15px;
        &:hover,
        &:focus {
          background-color: $primary;
        }
      }
    }
  }
  label {
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 24px;

    &.label-back {
      color: #7d9eb5 !important;
      font-family: $font3;
    }
  }
</style>

<script>
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  
  export default {
    name: 'InputSelect',
    components: {
      vSelect,
    },
    props: ['placeholder', 'label', 'color', 'fromBack', 'options' , 'selected'],
    methods: {
      updateValue(value) {
        this.$emit('change', value);
      },
    },
  };
</script>
