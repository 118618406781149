<template>
    <form class="login" @submit.prevent="submitForm">
        <h1 class="bold">Sign In</h1>
        <p>Log in to your account to continue</p>
        <div class="input-container">
            <label class="bold" for="">EMAIL</label>
            <input type="email" placeholder="Enter Email" v-model="email" required>
        </div>
        <div class="input-container">
            <div class="flex justify-between align-center">
                <label class="bold" for="">PASSWORD</label>
            </div>
            <div class="password">
                <input placeholder="Enter Password" type="password" ref="passwordInput" required v-model="password">
                <img src="@/assets/icon-eye.svg" alt="" @click="togglePassword">
            </div>
        </div>
        <button type="submit">Log in</button>
    </form>
</template>

<style lang="scss" scoped>
    .login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: white;
        width: 450px;
        text-align: center;
        padding: 40px;
        border-radius: 3px;
    }
    h1 {
        font-size: 30px;
    }
    p {
        font-size: 14px;
        color: #8DAABE;
        margin: 20px 0 50px 0;
    }
    .login .input-container {
        margin-bottom: 30px;
    }
    .password {
        position: relative;
        img {
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            right: 10px;
        }
    }
    a {
        font-size: 14px;
        color:#11A1FD;
    }
    button {
        width: 100%;
        background: #11A1FD;
        color: #fff;
        padding: 8px 0;
        font-size: 14px;
        line-height: 24px;
        margin-top: 15px;
    }
    input {
        width: 100% !important;
      border: 1px solid #7E9FB6;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 8px 15px;
      font-size: 14px;
      color: #000;
      outline: none;
      &::placeholder {
        color: #8BA9BD;
        font-weight: 500;
      }
    }
</style>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: "login",
        data() {
            return {
                email: '',
                password: ''
            }
        },
        methods: {
            ...mapActions(['login']),
            togglePassword() {
                if(this.$refs.passwordInput.type == 'password') {
                    this.$refs.passwordInput.type = 'text';
                }
                else {
                    this.$refs.passwordInput.type = 'password';
                }
            },
            submitForm() {
                const user = {
                    email: this.email,
                    password: this.password
                };
                this.login(user);
            }
        }

    };

</script>
