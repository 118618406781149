import router from "@/router";

export default {
  state: {
    featuredImages: [],
    featuredImage: {},
    loadingImages: false
  },
  mutations: {
    setFeaturedImages(state, featuredImages) {
      state.featuredImages = featuredImages;
    },
    addFeaturedImage(state, featuredImage) {
      state.featuredImages = [...state.featuredImages, featuredImage];
    },
    setFeaturedImage(state, featuredImage) {
      state.featuredImage = featuredImage;
    },
    setLoadingImages(state, value) {
      if(value == false) {
        setTimeout(() => {
          state.loadingImages = value;
        }, 1000);
      } else {
        state.loadingImages = value;
      }
    }
  }, 
  actions: {
    getFeaturedImages({ commit }) {
      commit("setLoadingImages", true);
      this._vm.$axios
        .get("featured-images")
        .then(({ data }) => {
          commit("setFeaturedImages", data.featuredImages[0]);
          commit("setLoadingImages", false);
        })
        .catch((err) => console.log(err));
    },
    addFeaturedImage({ commit }, { id, FeaturedImagesData }) {
      commit("setLoading", true, { root: true });
      this._vm.$axios
        .post(`featured-images/${id}/images`, FeaturedImagesData)
        .then(({ data }) => {
          if(data.status && data.status == 500) {
            throw new Error(data.message);
          }
          commit("setFeaturedImages", data.featuredImage);
          commit("setLoading", false, { root: true });
          this._vm.$toast.open(data.message);
        })
        .catch(err => {
          this._vm.$toast.open({
              message: err + "", 
              type: 'error'
          }) 
        });
    },
    getFeaturedImage({ commit }, featuredImage_id) {
      this._vm.$axios
        .get(`featured-images/${featuredImage_id}`)
        .then(({ data }) => {
          commit("setFeaturedImage", data.featuredImage);
        })
        .catch((err) => console.log(err));
    },
    deleteFeaturedImage({ commit, getters }, { id, images_ids }) {
      this._vm.$axios
        .post(`featured-images/${id}/images/delete`, { images_ids })
        .then(({ data }) => {
          this._vm.$toast.open(data.message);
        })
        .catch((err) => console.log(err));
    },
  },
  getters: {
    featuredImages(state) {
      return state.featuredImages;
    },
    featuredImage(state) {
      return state.featuredImage;
    },
    loadingImages(state) {
      return state.loadingImages;
    }
  },
};
