<template>
    <div class="participants main-spacing">
        <div class="mb-6 flex justify-between align-center">
            <h2 class="main-title">Participants</h2>
            <router-link to="/participants/register" class="register-btn">
                Register
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.50059C0 3.36798 0.0526785 3.2408 0.146447 3.14704C0.240215 3.05327 0.367392 3.00059 0.5 3.00059H6.293L4.146 0.854589C4.09951 0.808101 4.06264 0.752912 4.03748 0.692173C4.01232 0.631433 3.99937 0.566333 3.99937 0.500589C3.99937 0.434845 4.01232 0.369745 4.03748 0.309005C4.06264 0.248266 4.09951 0.193077 4.146 0.146589C4.19249 0.100101 4.24768 0.0632245 4.30842 0.0380654C4.36916 0.0129063 4.43426 -4.24385e-05 4.5 -4.24385e-05C4.56574 -4.24385e-05 4.63084 0.0129063 4.69158 0.0380654C4.75232 0.0632245 4.80751 0.100101 4.854 0.146589L7.854 3.14659C7.90056 3.19303 7.93751 3.24821 7.96271 3.30896C7.98792 3.3697 8.00089 3.43482 8.00089 3.50059C8.00089 3.56636 7.98792 3.63148 7.96271 3.69222C7.93751 3.75297 7.90056 3.80814 7.854 3.85459L4.854 6.85459C4.80751 6.90108 4.75232 6.93795 4.69158 6.96311C4.63084 6.98827 4.56574 7.00122 4.5 7.00122C4.43426 7.00122 4.36916 6.98827 4.30842 6.96311C4.24768 6.93795 4.19249 6.90108 4.146 6.85459C4.05211 6.7607 3.99937 6.63336 3.99937 6.50059C3.99937 6.43485 4.01232 6.36974 4.03748 6.30901C4.06264 6.24827 4.09951 6.19308 4.146 6.14659L6.293 4.00059H0.5C0.367392 4.00059 0.240215 3.94791 0.146447 3.85414C0.0526785 3.76037 0 3.6332 0 3.50059Z" fill="#0097AB"/>
                </svg>
            </router-link>
        </div>
        <Table :data="participants" />
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Table from '@/components/shared/Table.vue';
    
    export default {
        name: 'Participants',
        components: {
            Table
        },
        mounted() {
            this.getParticipants();
        },
        computed: {
            ...mapGetters(['participants'])
        },
        methods: {
            ...mapActions(['getParticipants'])
        }
    }
</script>