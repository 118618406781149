<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/speakers">Speakers</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Add Speaker</span>
        </div>
        <h2 class="add-title">Add Speaker</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="form-control">
                    <Dropzone :options="options" @fileAdded="onSpeakerAdded"></Dropzone>
                    <div class="multiple-container">
                        <div class="input-container">
                            <label class="bold" for="name">Full name</label>
                            <input type="text" required placeholder="Enter full name" name="name" id="name" v-model="speaker.title">
                        </div>
                        <div class="input-container">
                            <label class="bold" for="university">University</label>
                            <input type="text" required placeholder="Enter full university" name="university" id="university" v-model="speaker.university">
                        </div>
                    </div>
                </div>
                <button type="submit" class="primary-back-btn">Add Speaker</button>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import Dropzone from '@/components/back/Dropzone.vue';

    export default {
        name: 'AddSpeaker',
        data() {
            return {
                options: {
                    url: 'https://httpbin.org/post',
                    maxFilesize: 10,
                    headers: { 'My-Awesome-Header': 'header value' },
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Upload photo',
                },
                speaker: {
                    title: '',
                    photo: '',
                    university: ''
                }
            }
        },
        methods: {
            ...mapActions(['addSpeaker']),
            submitForm() {
                const speakerData = new FormData();
                Object.keys(this.speaker).forEach(item => {
                    speakerData.append("" + item, this.speaker[item]);
                });
                this.addSpeaker(speakerData);
                this.speaker = {
                    title: '',
                    photo: '',
                    speaker: ''
                }
            },
            onSpeakerAdded(file) {
                this.speaker.photo = file;
            }
        },
        components: {
            Dropzone
        }
    }
</script>