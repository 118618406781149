import Vue from "vue";

import VueRouter from "vue-router";
import NotFound from "@/components/NotFound.vue";
import BasePage from "@/views/front/BasePage.vue";
// Back
import BaseBack from "@/views/back/BaseBack.vue";
import Login from "@/views/back/Login.vue";
import ForgotPassword from "@/views/back/ForgotPassword.vue";
import BaseDashboard from "@/views/back/BaseDashboard.vue";
import TextsBackMain from "@/views/back/texts/Index.vue";
// Texts
import TextsBack from "@/views/back/texts/Texts.vue";
import TextBack from "@/views/back/texts/Text.vue";
import AddText from "@/views/back/texts/AddText.vue";
import EditText from "@/views/back/texts/EditText.vue";
// Committees
import CommitteesBack from "@/views/back/committees/Committees.vue";
import CommitteesBackMain from "@/views/back/committees/Index.vue";
import AddCommittee from "@/views/back/committees/AddCommittee.vue";
import EditCommittee from "@/views/back/committees/EditCommittee.vue";
// Special Issues
import SpecialIssuesMain from "@/views/back/special_issues/Index.vue";
import SpecialIssuesBack from "@/views/back/special_issues/SpecialIssues.vue";
import AddSpecialIssue from "@/views/back/special_issues/AddSpecialIssue.vue";
import EditSpecialIssue from "@/views/back/special_issues/EditSpecialIssue.vue";
// Categories
import CategoriesMain from "@/views/back/categories/Index.vue";
import CategoriesBack from "@/views/back/categories/Categories.vue";
import AddCategory from "@/views/back/categories/AddCategory.vue";
import EditCategory from "@/views/back/categories/EditCategory.vue";
// Important Dates
import ImportantDatesMain from "@/views/back/important_dates/Index.vue";
import ImportantDatesBack from "@/views/back/important_dates/ImportantDates.vue";
import AddImportantDate from "@/views/back/important_dates/AddImportantDate.vue";
import EditImportantDate from "@/views/back/important_dates/EditImportantDate.vue";
// Files
import FilesMain from "@/views/back/files/Index.vue";
import FilesBack from "@/views/back/files/Files.vue";
import AddFile from "@/views/back/files/AddFile.vue";
// Sponsors
import SponsorsMain from "@/views/back/sponsors/Index.vue";
import SponsorsBack from "@/views/back/sponsors/Sponsors.vue";
import AddSponsor from "@/views/back/sponsors/AddSponsor.vue";
import EditSponsor from "@/views/back/sponsors/EditSponsor.vue";
// Speakers
import SpeakersMain from "@/views/back/speakers/Index.vue";
import SpeakersBack from "@/views/back/speakers/Speakers.vue";
import AddSpeaker from "@/views/back/speakers/AddSpeaker.vue";
import EditSpeaker from "@/views/back/speakers/EditSpeaker.vue";
// Participants
import ParticipantsBackMain from "@/views/back/participants/Index.vue";
import ParticipantsBack from "@/views/back/participants/Participants.vue";
import ParticipantBack from "@/views/back/participants/Participant.vue";
// Contacts
import ContactsMain from "@/views/back/contacts/Index.vue";
import ContactsBack from "@/views/back/contacts/Contacts.vue";
import ContactBack from "@/views/back/contacts/Contact.vue";
//Featured Images
import FeaturedImages from "@/views/back/FeaturedImages.vue";
//Venue
import VenueMain from "@/views/back/venue/Index.vue";
import VenueDetails from "@/views/back/venue/VenueDetails.vue";
import VenueImages from "@/views/back/venue/VenueImages.vue";
// HOME
import Home from "@/views/front/Home.vue";
// Conference
import Conference from "@/views/front/conference/Index.vue";
import Speakers from "@/views/front/conference/Speakers.vue";
import ImportantDates from "@/views/front/conference/ImportantDates.vue";
import Committees from "@/views/front/conference/committees/Committees.vue";
import Committee from "@/views/front/conference/committees/Committee.vue";
//Proceeding
import Proceeding from "@/views/front/Proceeding.vue";
//Venue
import Venue from "@/views/front/Venue.vue";
// Participants
import ParticipantsMain from "@/views/front/participants/Index.vue";
import Participants from "@/views/front/participants/Participants.vue";
import Register from "@/views/front/participants/Register.vue";
// Contact
import Contact from "@/views/front/Contact.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BasePage,
    children: [
      {
        path: "/",
        component: Home,
      },
      {
        path: "/conference",
        component: Conference,
        redirect: "/conference/speakers",
        children: [
          {
            path: "speakers",
            component: Speakers,
          },
          {
            path: "important-dates",
            component: ImportantDates,
          },
          {
            path: "committees",
            component: Committees,
            children: [
              {
                path: ":id",
                component: Committee,
              },
            ],
          },
        ],
      },
      {
        path: "/proceeding",
        component: Proceeding,
      },
      {
        path: "/venue",
        component: Venue,
      },
      {
        path: "/participants",
        component: ParticipantsMain,
        children: [
          {
            path: "/",
            component: Participants,
          },
          {
            path: "register",
            component: Register,
          },
        ],
      },
      {
        path: "/contact",
        component: Contact,
      },
    ],
  },
  {
    path: "/admin",
    component: BaseBack,
    children: [
      {
        path: "login",
        component: Login,
        meta: {
          noAuth: true,
        },
      },
      {
        path: "forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/",
        component: BaseDashboard,
        meta: {
          auth: true,
        },
        children: [
          {
            path: "texts",
            component: TextsBackMain,
            children: [
              {
                path: "/",
                component: TextsBack,
              },
              {
                path: "add",
                component: AddText,
              },
              {
                path: ":id",
                component: TextBack,
              },
              {
                path: ":id/edit",
                component: EditText,
              },
            ],
          },
          {
            path: "committees",
            component: CommitteesBackMain,
            children: [
              {
                path: "/",
                component: CommitteesBack,
              },
              {
                path: "add",
                component: AddCommittee,
              },
              {
                path: ":id/edit",
                component: EditCommittee,
              },
            ],
          },
          {
            path: "special-issues",
            component: SpecialIssuesMain,
            children: [
              {
                path: "/",
                component: SpecialIssuesBack,
              },
              {
                path: "add",
                component: AddSpecialIssue,
              },
              {
                path: ":id/edit",
                component: EditSpecialIssue,
              },
            ],
          },
          {
            path: "categories",
            component: CategoriesMain,
            children: [
              {
                path: "/",
                component: CategoriesBack,
              },
              {
                path: "add",
                component: AddCategory,
              },
              {
                path: ":id/edit",
                component: EditCategory,
              },
            ],
          },
          {
            path: "important-dates",
            component: ImportantDatesMain,
            children: [
              {
                path: "/",
                component: ImportantDatesBack,
              },
              {
                path: "add",
                component: AddImportantDate,
              },
              {
                path: ":id/edit",
                component: EditImportantDate,
              },
            ],
          },
          {
            path: "files",
            component: FilesMain,
            children: [
              {
                path: "/",
                component: FilesBack,
              },
              {
                path: "add",
                component: AddFile,
              },
            ],
          },
          {
            path: "sponsors",
            component: SponsorsMain,
            children: [
              {
                path: "/",
                component: SponsorsBack,
              },
              {
                path: "add",
                component: AddSponsor,
              },
              {
                path: ":id/edit",
                component: EditSponsor,
              },
            ],
          },
          {
            path: "speakers",
            component: SpeakersMain,
            children: [
              {
                path: "/",
                component: SpeakersBack,
              },
              {
                path: "add",
                component: AddSpeaker,
              },
              {
                path: ":id/edit",
                component: EditSpeaker,
              },
            ],
          },
          {
            path: "participants",
            component: ParticipantsBackMain,
            children: [
              {
                path: "/",
                component: ParticipantsBack,
              },
              {
                path: ":id",
                component: ParticipantBack,
              },
            ],
          },
          {
            path: "contacts",
            component: ContactsMain,
            children: [
              {
                path: "/",
                component: ContactsBack,
              },
              {
                path: ":id",
                component: ContactBack,
              },
            ],
          },
          {
            path: "featured-images",
            component: FeaturedImages,
          },
          {
            path: "venue",
            component: VenueMain,
            children: [
              {
                path: "details",
                component: VenueDetails,
              },
              {
                path: "images",
                component: VenueImages,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Check for auth
router.beforeEach((to, from, next) => {
  const token = JSON.parse(window.localStorage.getItem("nprojectToken"));
  if (to.matched.some((route) => route.meta.auth)) {
    if (token) {
      next();
    } else {
      if (!(from.path == "/admin/login" && to.path == "/admin")) {
        next({
          path: "/admin/login",
        });
      } else {
        next();
      }
    }
  } else {
    if (token && to.path == "/admin/login") {
      next({
        path: "/admin",
      });
    }
    next();
  }
});

export default router;
