import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Global components
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ImageModal from '@/components/shared/ImageModal.vue';
import Loading from '@/components/back/Loading.vue';
Vue.component('ImageModal', ImageModal);
Vue.component('VueDropzone', vue2Dropzone);
Vue.component('Loading', Loading);

// Directives
import clickOutsideDirective from '@/directives/click-outside.directive';
Vue.directive('click-outside', clickOutsideDirective);

// Mixins
import closeEsc from './mixins/closeEsc.mixin';
Vue.mixin(closeEsc);

// Plugins
import axios from './plugins/axios';
Vue.use(axios);

// Notifications
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

