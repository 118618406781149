<template>
    <transition name="fadepage" mode="out-in">
        <div class="background">
            <router-view />
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .background{
        background-color:#F3F7F9;
        height: 100vh;
        overflow: auto;
    }
</style>

<script>
    export default {
        name: 'BaseBack',
    }
</script>