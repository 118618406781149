<template>
  <div class="files">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteFile" />
    <div class="flex align-center justify-between files-head">
      <h3>File</h3>
      <router-link to="/admin/files/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add File</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-left">Title</th>
          <th class="text-left">File</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!files.length && !loading"><td class="text-left semibold pl-3" colspan="4">No files were found. Please add some!</td></tr>
        <tr v-for="(file, index) in files" :key="file._id">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-left semibold">{{ file.title }}</td>
          <td class="text-left">
            Download file
            <a :href="file.file" target="_blank" :download="file.title"><img src="@/assets/icon-download.svg" alt=""/></a>
          </td>
          <td class="text-center">
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="fileDeletion(file)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!files.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .files {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      @include for-small-phone-only{
        overflow: auto;
        width: 150%;
      }
      img {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';
  
  export default {
    name: 'Files',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getFiles();
    },
    computed: {
      ...mapGetters(['files','loading'])
    },
    methods: {
      ...mapActions(['getFiles', 'deleteFile']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      fileDeletion(file) {
        this.deleteShownModal = true;
        this.deletion_id = file;
      },
      onDeleteFile() {
        this.deleteFile(this.deletion_id);
        this.deleteShownModal = false;
      }
    },
    components: {
      DeleteModal,
    },
  }
</script>