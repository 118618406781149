<template>
  <div class="sponsors">
    <DeleteModal v-if="deleteShownModal" :click="closeDeleteModal" @onDelete="onDeleteSponsor" />
    <div class="flex align-center justify-between sponsors-head">
      <h3>Sponsors</h3>
      <router-link to="/admin/sponsors/add" class="primary-back-btn"
        ><img src="@/assets/icon-plus.svg" alt="" />Add Sponsor</router-link
      >
    </div>
    <div class="adminTable">
      <table>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">Photo</th>
          <th class="text-left">Title</th>
          <th class="text-left">Link</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr v-if="!sponsors.length && !loading"> 
<td class="text-left semibold pl-3" colspan="4">No files were found. Please add some!</td>
        </tr>
        <tr v-for="(sponsor, index) in sponsors" :key="sponsor._id">
          <td class="text-center">{{index + 1 }}</td>
          <td class="text-center">
            <div
              class="photo-container"
              :style="
                `background-image: url(${sponsor.photo})`
              "
            ></div>
          </td>
          <td class="text-left">{{ sponsor.title }}</td>
          <td class="text-left"><a :href="sponsor.link" target="_blank">Go to website</a></td>
          <td class="text-center">
            <router-link :to="`/admin/sponsors/${sponsor._id}/edit`"
              ><img src="@/assets/icon-edit.svg" alt=""
            /></router-link>
            <img
              src="@/assets/icon-delete.svg"
              alt=""
              @click.stop="sponsorDeletion(sponsor)"
            />
          </td>
        </tr>
      </table>
      <div v-if="!sponsors.length && loading" class="loader"><Loading /></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .photo-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: contain;
    margin: auto;
  }
  .sponsors {
    &-head {
      margin-bottom: 25px;
      h3 {
        font-size: 24px;
        @include for-phone-only {
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
    table {
      img {
        margin: 0 5px;
        cursor: pointer;
      }
      a {
        color: #11a1fd;
        text-decoration: underline;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import DeleteModal from '@/components/back/DeleteModal.vue';

  export default {
    name: 'Sponsors',
    data() {
      return {
        deleteShownModal: false,
        deletion_id: ''
      };
    },
    mounted() {
      this.getSponsors();
    },
    computed: {
      ...mapGetters(['sponsors','loading'])
    },
    methods: {
      ...mapActions(['getSponsors', 'deleteSponsor']),
      closeDeleteModal() {
        this.deleteShownModal = false;
      },
      sponsorDeletion(sponsor) {
        this.deleteShownModal = true;
        this.deletion_id = sponsor;
      },
      onDeleteSponsor() {
        this.deleteSponsor(this.deletion_id);
        this.deleteShownModal = false;
      }
      
    },
    components: {
      DeleteModal,
    },
  };
</script>
