<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/special-issues">Special Issues</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Edit Special Issue</span>
        </div>
        <h2 class="add-title">Edit special issue</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="input-container">
                    <label class="bold" for="title">Title</label>
                    <input type="text" required placeholder="Enter title" name="title" id="title" v-model="specialIssue.title">
                </div>
                <div class="input-container">
                    <label class="bold" for="link">Link</label>
                    <input type="text" required placeholder="Enter link" name="link" id="link" v-model="specialIssue.link">
                </div>
                <button type="submit" class="primary-back-btn">Save Special Issue</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .input-container {
        margin-bottom: 30px;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'EditSpecialIssue',
        mounted() {
            this.getSpecialIssue(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['specialIssue'])
        },
        methods: {
            ...mapActions(['getSpecialIssue', 'editSpecialIssue']),
            submitForm() {
                this.editSpecialIssue(this.specialIssue);
            }
        }
    }
</script>