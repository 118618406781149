<template>
    <div class="add-view">
        <div class="breadcrumb">
            <router-link to="/admin/categories">Categories</router-link>
            <img src="@/assets/icon-back-arrow-right.svg">
            <span>Edit Category</span>
        </div>
        <h2 class="add-title">Edit category</h2>
        <div class="add-form-container">
            <form @submit.prevent="submitForm">
                <div class="input-container">
                    <label class="bold" for="category">Category</label>
                    <input type="text" placeholder="Enter category" name="category" id="category" v-model="category.title">
                </div>
                <button type="submit" class="primary-back-btn">Save Category</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .input-container {
        margin-bottom: 30px;
    }
</style>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'EditCategory',
        mounted() {
            this.getCategory(this.$route.params.id);
        },
        computed: {
            ...mapGetters(['category'])
        },
        methods: {
            ...mapActions(['getCategory', 'editCategory']),
            submitForm() {
                this.editCategory(this.category);
            }
        }
    }
</script>